<template>
  <v-chip color="primary" size="large"><v-icon icon="mdi-bookmark-check" /> &nbsp; {{ merchantsStore.currentMerchant?.legalEntityName }}</v-chip>

  <BillingEmail v-if="auth.hasCompanyScoped(Permission.Company_UpdateDetails)" class="my-4" />

  <v-expansion-panels v-if="canDeactivate" class="mt-16">
    <v-expansion-panel
      expand-icon="mdi-store-remove-outline"
      collapse-icon="mdi-store-remove-outline"
      @group:selected="({ value }) => value && stepDeactivationFlow('expand')"
    >
      <v-expansion-panel-title>
        <span class="text-error">Close company account</span>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <div v-if="customerInContract">
          <p>
            You are currently in a contractual agreement with yetipay. To discuss your terms or close your company account, contact
            <a href="mailto:help@yetipay.me">help@yetipay.me</a>, or your account manager.
          </p>
        </div>

        <div v-else>
          <p>Once you close your company account, you will no longer be able to take payments on your card machine(s).</p>
          <p class="mt-4 d-flex justify-end">
            <v-btn color="error" :loading="loading" prepend-icon="mdi-delete" @click="stepDeactivationFlow('dialog', () => (isDialogActive = true))">
              Close
            </v-btn>
          </p>
        </div>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>

  <v-dialog v-model="isDialogActive" max-width="500px">
    <v-card>
      <template #title>
        <h3 v-if="currentStep === Step.Final" class="text-error">Company account closed</h3>
        <h3 v-else class="text-error">Close company account</h3>
      </template>
      <template #text>
        <v-window v-model="currentStep">
          <v-window-item :value="Step.Confirm">
            <p>
              <v-combobox v-model="reason" :items="[...possibleChurnReasons, 'Other']" label="Why do you want to cancel?" />
              <transition>
                <v-text-field v-if="reason === 'Other'" v-model="otherReason" label="Tell us more" />
              </transition>
            </p>
            <br />
            <p>Once you close your company account, you will no longer be able to take payments on your card machine(s).</p>
            <br />
            <p>
              You will need to pay any outstanding balance to yetipay, and return your card machine(s) to us by following the instructions in the next step.
            </p>
          </v-window-item>

          <v-window-item :value="Step.Final">
            <p>Your company account is now closed.</p>
            <br />
            <p>You'll need to return your card machines using the below link.</p>
            <p><a href="https://www.royalmail.com/track-my-return#/details/5864" target="_blank">https://www.royalmail.com/track-my-return#/details/5864</a></p>
            <br />
            <p><b>You are liable for further charges should you not return the device(s) within 10 business days.</b></p>
          </v-window-item>
        </v-window>
      </template>
      <template #actions>
        <v-window v-model="currentStep" :touch="false" class="d-flex flex-fill">
          <!-- if a class is present, the window item will always show... -->
          <v-window-item :value="Step.Confirm" :class="currentStep === Step.Confirm ? 'd-flex' : ''">
            <v-btn-nd text="Cancel" class="ms-4 my-4" @click="isDialogActive = false" />
            <v-spacer />
            <v-btn
              color="error"
              class="bg-error on-error me-4 my-4"
              text="Continue"
              :loading="loading"
              :disabled="!realReason"
              @click="stepDeactivationFlow('confirm', () => (currentStep = Step.Final))"
            />
          </v-window-item>

          <v-window-item :value="Step.Final" class="d-flex">
            <v-spacer />
            <v-btn class="bg-primary on-primary me-4 my-4" text="Done" @click="closeFinalStep" />
          </v-window-item>
        </v-window>
      </template>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, inject, computed, onMounted } from 'vue';
import { useAuthorizationStore, Permission } from '@/store/authorization';
import { useMerchantsStore } from '@/store/merchants';
import { API } from '@/plugins/api';

const auth = useAuthorizationStore();
const merchantsStore = useMerchantsStore();

const api = inject<API>('api');

enum Step {
  Confirm = 'confirm',
  Final = 'final',
}
const possibleChurnReasons = [
  'Yetipay is missing one or more features that I need',
  'Billing issue',
  'I can get better rates elsewhere',
  "I'm moving to cash only",
  'My business is closing down',
  'Technical issues with yetipay devices',
  'I only needed a card machine temporarily',
];

const canDeactivate = computed(
  () =>
    (!merchantsStore.currentMerchant || !merchantsStore.hasMerchantChurned(merchantsStore.currentMerchant)) &&
    auth.hasCompanyScoped(Permission.Company_Deactivate) &&
    auth.hasCompanyScoped(Permission.Company_GetRates),
);
const customerInContract = ref<boolean | undefined>(false);
const isDialogActive = ref(false);
const reason = ref('');
const otherReason = ref('');
const realReason = computed(() => (reason.value === 'Other' ? otherReason.value : reason.value));
const currentStep = ref(Step.Confirm);
const loading = ref(false);

onMounted(async () => {
  if (!canDeactivate.value) {
    return;
  }
  const deal = await api!.merchant.getDeal(merchantsStore.currentMerchant!.merchantId);
  customerInContract.value = (deal.contractEndDate ?? '') > new Date().toISOString();
});

const stepDeactivationFlow = async (step: 'expand' | 'dialog' | 'confirm', callback?: () => void) => {
  if (step === 'expand' && !customerInContract.value) {
    return;
  }
  const stage = step === 'expand' ? 'in-contract' : step === 'dialog' ? 'init' : 'complete';
  const reason = step === 'confirm' && realReason.value ? realReason.value : undefined;
  loading.value = true;
  try {
    await api!.merchant.deactivate(merchantsStore.currentMerchant!.merchantId, stage, reason);
    await merchantsStore.initialize();
    if (callback) {
      callback();
    }
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
};
const closeFinalStep = () => {
  setTimeout(() => (currentStep.value = Step.Confirm), 300); // So that users don't see the final step before the fade out
  isDialogActive.value = false;
};
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

div.v-window >>> div.v-window__container {
  display: flex;
  flex-grow: 1;
}
</style>
