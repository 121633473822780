<template>
  <v-autocomplete v-model="siteId" label="Filter by site" item-title="siteName" item-value="siteId" :loading="sitesLoading" :items="sites" clearable />
</template>

<script setup lang="ts">
import { inject, onMounted, ref } from 'vue';
import { API } from '@/plugins/api';
import { SiteReduced } from '@/api/merchant-service/site';

const siteId = defineModel<string | undefined>();
const sites = ref<SiteReduced[]>([]);
const sitesLoading = ref(false);

const api = inject<API>('api');

onMounted(async () => {
  sitesLoading.value = true;
  sites.value = await api!.site.listAllSites();
  if (sites.value.length === 1) {
    siteId.value = sites.value[0].siteId;
  }
  sitesLoading.value = false;
});
</script>
