<template>
  <SiteForm
    v-model="site"
    mode="update"
    :is-data-loading="loadingData"
    :is-primary-loading="loadingSubmit"
    :primary-error="submitError"
    :merchant-country="merchantsStore.currentMerchant?.country"
    @primary="formSubmitted"
  />
</template>

<script setup lang="ts">
import { inject, ref, onBeforeMount, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useApplicationStore } from '@/store/application';
import { useMerchantsStore } from '@/store/merchants';
import { API } from '@/plugins/api';
import { Site } from '@/api/merchant-service/site';

const api = inject<API>('api');

const route = useRoute();
const router = useRouter();
const applicationStore = useApplicationStore();
const merchantsStore = useMerchantsStore();

const loadingData = ref(false);
const loadingSubmit = ref(false);
const submitError = ref<string | undefined>(undefined);
const siteId = ref<string | undefined>(undefined);
const site = ref<Site>({
  name: '',
  displayName: '',
  phoneNumber: '',
  address: {
    line1: '',
    city: '',
    postalCode: '',
    country: '',
  },
} as Site);

onBeforeMount(() => (siteId.value = route.params.siteId as string));

onMounted(async () => {
  loadingData.value = true;
  try {
    site.value = await api!.site.getSiteDetails(siteId.value!);
    if (!site.value.displayName) {
      site.value.displayName = site.value.name;
    }
  } catch (error) {
    console.error(error);
  } finally {
    loadingData.value = false;
  }
});

const formSubmitted = async () => {
  submitError.value = undefined;
  loadingSubmit.value = true;
  try {
    await api!.site.updateSiteDetails(siteId.value!, site.value!);
    applicationStore.notifyUser({ message: 'Modification saved' });
    router.push('/sites');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    const message = error.response?.data?.error;
    submitError.value = message ?? 'An error ocurred when processing this request';
  } finally {
    loadingSubmit.value = false;
  }
};
</script>
