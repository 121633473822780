<template>
  <div class="on-secondary" align="center">
    <h1 class="d-flex justify-center">Welcome to Basecamp!</h1>
    <h3 class="d-flex justify-center my-15">Our team is working on getting your account ready, it will be ready soon.</h3>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthenticationStore } from '@/store/authentication';
import { useMerchantsStore } from '@/store/merchants';

const authStore = useAuthenticationStore();
const merchantsStore = useMerchantsStore();
const router = useRouter();

onMounted(() => {
  refresh();
});

const refresh = async () => {
  try {
    await authStore.refreshToken();
    if (authStore.user.isConfigured) {
      await merchantsStore.initialize();
    }
  } catch (e) {
    console.error(e);
  }

  if (authStore.user.isConfigured && merchantsStore.merchantsFetched) {
    router.push('/');
  } else {
    setTimeout(refresh, 1000);
  }
};
</script>
