<template>
  <v-app>
    <router-view></router-view>
    <transition name="fade" mode="out-in">
      <v-progress-linear v-if="showLoadingBar" id="app-loading-bar" color="primary" indeterminate></v-progress-linear>
    </transition>
    <Toast />
  </v-app>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useApplicationStore } from '@/store/application';

const applicationStore = useApplicationStore();
const showLoadingBar = computed(() => applicationStore.isApplicationLoading);
</script>

<style>
@import '@/assets/css/variables.css';
@import '@/assets/css/transitions.css';
</style>
