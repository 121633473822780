<template>
  <h2 class="mt-5">Integrations</h2>
  <v-card class="justify-start mt-5" subtitle="Configuration for Till > Yeti integration">
    <template #title>
      <span class="font-weight-black">Yeti Connect Push</span>
      <v-chip v-if="connectConfig" class="ml-3" :color="connectConfig?.connection_id === 'DISCONNECTED' ? 'red' : 'green'">
        {{ `${connectConfig?.connection_id === 'DISCONNECTED' ? 'Not Connected' : 'Connected'}` }}
      </v-chip>
    </template>
    <v-card-text v-if="connectConfigResponseStatus === 200">
      <span>Boarded as store: {{ connectConfig?.site_id }}</span>
      <div>Integration Type: NCR-V1</div>
      <div>Live Connection ID: {{ connectConfig?.connection_id }}</div>
      <div>EPOS Auth Token: {{ connectConfig?.authentication?.redacted_token || 'Not set' }}</div>
    </v-card-text>
    <v-card-text v-else-if="connectConfigResponseStatus === 404">
      <div>{{ connectConfigLoadError }}</div>
      <div class="d-flex justify-end">
        <v-btn class="mt-2" @click="boardIntegration('NCR-V1')">Board Site</v-btn>
      </div>
    </v-card-text>
    <v-progress-linear :active="connectConfigResponseStatus === 'LOADING'" color="deep-purple" height="4" indeterminate />
  </v-card>
</template>

<script setup lang="ts">
import { ref, onMounted, inject } from 'vue';
import { useApplicationStore } from '@/store/application';
import { SiteConnectConfig } from '@/api/merchant-service/site-internal-settings';
import { API } from '@/plugins/api';

const api = inject<API>('api');
const applicationStore = useApplicationStore();
const props = defineProps<{ siteId: string }>();

const connectConfig = ref<SiteConnectConfig | undefined>(undefined);
const connectConfigResponseStatus = ref<number | 'LOADING'>('LOADING');
const connectConfigLoadError = ref<string | undefined>(undefined);

onMounted(async () => {
  try {
    connectConfig.value = await api!.siteInternalSettings.fetchIntegrationConfig(props.siteId);
    connectConfigResponseStatus.value = 200;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    connectConfigResponseStatus.value = error?.response?.status;
    if (error?.response?.status === 404) {
      connectConfigLoadError.value = 'This site is not yeti configured for this integration. Please board the site to continue setup.';
      return;
    }
    connectConfigLoadError.value = 'Failed to fetch integration config for site';
    applicationStore.notifyUser({ message: 'Failed to fetch integration config for site', type: 'error' });
  }
});

const boardIntegration = async (integrationType: string) => {
  applicationStore.notifyUser({ message: `Not implemented yet for ${integrationType}`, type: 'error' });
};
</script>
