import 'vanilla-cookieconsent/dist/cookieconsent.css';
import * as CookieConsent from 'vanilla-cookieconsent';
import { App } from 'vue';

export default {
  install: (app: App) => {
    const config: CookieConsent.CookieConsentConfig = {
      categories: {
        necessary: {
          enabled: true,
          readOnly: true,
        },
        analytics: {},
      },
      guiOptions: {
        consentModal: {
          equalWeightButtons: false,
        },
        preferencesModal: {
          equalWeightButtons: false,
        },
      },

      language: {
        default: 'en',
        translations: {
          en: {
            consentModal: {
              title: 'We use cookies',
              description:
                'Cookies help us address issues and improve your basecamp experience. ' +
                'Find more information in our <a href="https://yetipay.me/privacy/" target="_blank">privacy policy</a>.',
              acceptAllBtn: 'Accept all',
              acceptNecessaryBtn: 'Reject all',
              showPreferencesBtn: 'Manage cookies',
            },
            preferencesModal: {
              title: 'Manage cookie preferences',
              acceptAllBtn: 'Accept all',
              acceptNecessaryBtn: 'Reject all',
              savePreferencesBtn: 'Accept current selection',
              closeIconLabel: 'Close modal',
              sections: [
                {
                  title: 'How do we use cookies?',
                  description: 'We only use cookies where we need them, to enhance your basecamp experience or help us analyse any issues.',
                },
                {
                  title: 'Strictly necessary cookies',
                  description: 'These cookies are essential for the proper functioning of basecamp and cannot be disabled.',
                  linkedCategory: 'necessary',
                },
                {
                  title: 'Performance and analytics',
                  description:
                    'These cookies collect information about how you use our website. ' + 'All of the data is anonymized and cannot be used to identify you.',
                  linkedCategory: 'analytics',
                },
                {
                  title: 'More information',
                  description:
                    'For any queries in relation to my policy on cookies and your choices, ' +
                    'read our <a href="https://yetipay.me/cookies/" target="_blank">Cookie Policy</a> ' +
                    'or please email <a href="mailto:help@yetipay.me">help@yetipay.me</a>.',
                },
              ],
            },
          },
        },
      },

      onConsent: () => {
        if (!CookieConsent.acceptedCategory('analytics')) {
          app.config.globalProperties.$posthog.opt_out_capturing();
        } else {
          app.config.globalProperties.$posthog.opt_in_capturing();
        }
      },
    };
    CookieConsent.run(config);
    app.config.globalProperties.$CookieConsent = CookieConsent;
    app.provide('CookieConsent', CookieConsent);
  },
};
