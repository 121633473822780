<template>
  <v-sheet :elevation="disabled ? '0' : '3'" rounded="sm" align="center" class="main">
    <v-img v-if="img" :src="img" />
    <v-sheet class="pa-5 font-weight-black">
      <h2 class="text-primary text-font-gothic">{{ title }}</h2>
      <div class="mb-5"><slot name="subtitle"></slot></div>
      <v-btn-nd icon="mdi-minus" size="small" variant="flat" :disabled="disabled || count <= 0" @click="count--" />
      <span class="mx-5">{{ count }}</span>
      <v-btn-nd icon="mdi-plus" size="small" variant="flat" :disabled="disabled || count >= maxValue" @click="count++" />
    </v-sheet>
    <div v-if="disabled" class="overlay">
      <div class="overlay-content">
        <div class="bg-error py-3 overlay-text">Currently unavailable</div>
        <div class="on-error overlay-text-secondary"><i>Back in stock soon</i></div>
      </div>
    </div>
  </v-sheet>
</template>

<script setup lang="ts">
const count = defineModel<number>('count', { default: 0 });

defineProps({
  title: {
    type: String,
    required: true,
  },
  img: {
    type: String,
    default: undefined,
  },
  maxValue: {
    type: Number,
    default: 100,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
</script>

<style scoped>
.main {
  background-color: #f8f8f8;
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
}

.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  width: 100%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.4);
  overflow: hidden;
}

.overlay-content {
  position: absolute;
  top: 40%;
  left: -50%;
  transform: rotate(-25deg);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200%;
}

.overlay-text {
  width: 100%;
}

.overlay-text-secondary {
  background-color: rgba(var(--v-theme-error), 0.8);
  width: 100%;
}
</style>
