import { Amplify } from 'aws-amplify';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: import.meta.env.VITE_USER_POOL_ID,
      userPoolClientId: import.meta.env.VITE_USER_POOL_WEB_CLIENT_ID,
      signUpVerificationMethod: 'code',
      loginWith: {
        oauth: {
          domain: import.meta.env.VITE_COGNITO_GOOGLE_OAUTH_DOMAIN,
          scopes: ['email', 'openid', 'profile'],
          redirectSignIn: import.meta.env.VITE_REDIRECT_SIGN_IN.split(','),
          redirectSignOut: import.meta.env.VITE_REDIRECT_SIGN_OUT.split(','),
          responseType: 'token',
          providers: ['Google'],
        },
      },
    },
  },
});
