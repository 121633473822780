export const tradedCountries = [
  { code: 'AT', name: 'Austria' },
  { code: 'BE', name: 'Belgium' },
  { code: 'CY', name: 'Cyprus' },
  { code: 'EE', name: 'Estonia' },
  { code: 'FI', name: 'Finland' },
  { code: 'FR', name: 'France' },
  { code: 'DE', name: 'Germany' },
  { code: 'GR', name: 'Greece' },
  { code: 'IE', name: 'Ireland' },
  { code: 'IT', name: 'Italy' },
  { code: 'LV', name: 'Latvia' },
  { code: 'LT', name: 'Lithuania' },
  { code: 'LU', name: 'Luxembourg' },
  { code: 'MT', name: 'Malta' },
  { code: 'NL', name: 'Netherlands' },
  { code: 'NO', name: 'Norway' },
  { code: 'PT', name: 'Portugal' },
  { code: 'SK', name: 'Slovakia' },
  { code: 'SI', name: 'Slovenia' },
  { code: 'ES', name: 'Spain' },
  { code: 'SE', name: 'Sweden' },
  { code: 'GB', name: 'United Kingdom' },
];

export const tradedPaymentMethods = [
  {
    name: 'Visa',
    code: 'visa',
    mandatory: true,
  },
  {
    name: 'Mastercard',
    code: 'mc',
    mandatory: true,
  },
  {
    name: 'American Express',
    code: 'amex',
  },
];

export const phoneCountryCodes = [
  { text: 'Austria (+43)', value: '+43' },
  { text: 'Belgium (+32)', value: '+32' },
  { text: 'Bulgaria (+359)', value: '+359' },
  { text: 'Croatia (+385)', value: '+385' },
  { text: 'Cyprus (+357)', value: '+357' },
  { text: 'Czech Republic (+420)', value: '+420' },
  { text: 'Denmark (+45)', value: '+45' },
  { text: 'Estonia (+372)', value: '+372' },
  { text: 'Finland (+358)', value: '+358' },
  { text: 'France (+33)', value: '+33' },
  { text: 'Germany (+49)', value: '+49' },
  { text: 'Greece (+30)', value: '+30' },
  { text: 'Hungary (+36)', value: '+36' },
  { text: 'Iceland (+354)', value: '+354' },
  { text: 'Ireland (+353)', value: '+353' },
  { text: 'Italy (+39)', value: '+39' },
  { text: 'Latvia (+371)', value: '+371' },
  { text: 'Lithuania (+370)', value: '+370' },
  { text: 'Luxembourg (+352)', value: '+352' },
  { text: 'Malta (+356)', value: '+356' },
  { text: 'Netherlands (+31)', value: '+31' },
  { text: 'Norway (+47)', value: '+47' },
  { text: 'Poland (+48)', value: '+48' },
  { text: 'Portugal (+351)', value: '+351' },
  { text: 'Romania (+40)', value: '+40' },
  { text: 'Russia (+7)', value: '+7' },
  { text: 'Slovakia (+421)', value: '+421' },
  { text: 'Slovenia (+386)', value: '+386' },
  { text: 'Spain (+34)', value: '+34' },
  { text: 'Sweden (+46)', value: '+46' },
  { text: 'Switzerland (+41)', value: '+41' },
  { text: 'Turkey (+90)', value: '+90' },
  { text: 'Ukraine (+380)', value: '+380' },
  { text: 'UK (+44)', value: '+44' },
];

export const transactionStatuses = [
  {
    name: 'Captured',
    code: 'captured',
  },
  {
    name: 'Failed',
    code: 'failed',
  },
  {
    name: 'Refunded',
    code: 'refunded',
  },
  {
    name: 'Refund pending',
    code: 'refund-pending',
  },
];
