<template>
  <v-alert type="info" variant="outlined"
    >You are editing the internal settings for site "<kbd>{{ siteName }}</kbd
    >".</v-alert
  >
  <h2 class="mt-5">Tips</h2>
  <div class="d-flex justify-start flex-wrap">
    <v-switch v-model="uiSettings.tips.enabled" label="Enable tips for site" inset color="success" class="mx-2" />
    <div class="d-flex justify-space-evenly flex-grow-1 flex-wrap">
      <v-select
        v-if="uiSettings.tips.enabled"
        v-model="uiSettings.tips.provider"
        label="Select the tip provider"
        :items="tipsProviders"
        :error-messages="(v$.tips.provider.$silentErrors.map((e) => e.$message) as string[]) || []"
        class="mx-2"
        style="min-width: 200px"
      />
      <v-text-field
        v-if="uiSettings.tips.enabled && uiSettings.tips.provider === 'tipjar'"
        v-model="uiSettings.tips.tipjarCode"
        label="TipJar's shortcode for site"
        clearable
        :error-messages="(v$.tips.tipjarCode.$silentErrors.map((e) => e.$message) as string[]) || []"
        class="mx-2"
        style="min-width: 230px"
      />
    </div>
  </div>
  <div class="d-flex justify-end">
    <v-checkbox
      v-if="uiSettings.tips.enabled"
      v-model="uiSettings.tips.skipTipsIfServiceChargeIncluded"
      label="Skip tip modal when service charge is included"
      inset
      color="primary"
      class="mx-2"
    />
  </div>
  <div class="d-flex justify-end">
    <v-btn :loading="updateLoading" :disabled="!settingsChanged || v$.$invalid" @click="updateInternalSettings">save changes</v-btn>
  </div>

  <internal-connect-settings :site-id="siteId" />

  <h2 class="mt-15">Payouts</h2>
  <div v-if="payoutSettings">
    <site-payout-config
      v-for="payoutConfig in payoutSettings"
      :key="payoutConfig.id"
      :config="payoutConfig"
      :transfer-instruments="transferInstruments"
      :site-id="siteId"
    />
  </div>
  <div v-else>Merchant has no payout settings.</div>

  <h2 class="mt-15">Payment Methods</h2>
  <div id="site-payment-methods" class="d-flex">
    <v-table>
      <thead>
        <tr>
          <th class="text-left font-weight-bold">Name</th>
          <th class="text-left font-weight-bold">Currencies</th>
          <th class="text-left font-weight-bold">Verification Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="method in paymentMethods" :key="method.id">
          <td>{{ method.name.replace('_', ' ') }}</td>
          <td>{{ method.currencies.join(', ') }}</td>
          <td>{{ method.verificationStatus ?? 'N/A' }}</td>
          <td>
            <v-btn
              density="compact"
              :loading="method.updateLoading"
              :class="method.enabled ? 'bg-error' : 'bg-success'"
              @click="togglePaymentMethodStatus(method.id)"
            >
              {{ method.enabled ? 'Disable' : 'Enable' }}
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, watch, inject } from 'vue';
import { useRoute } from 'vue-router';
import { useVuelidate } from '@vuelidate/core';
import { requiredIf } from '@vuelidate/validators';
import { useApplicationStore } from '@/store/application';
import { Settings, PaymentMethod, PayoutSettings, TransferInstrument } from '@/api/merchant-service/site-internal-settings';
import { API } from '@/plugins/api';
import { captureException } from '@sentry/vue';

const api = inject<API>('api');

const applicationStore = useApplicationStore();
const siteId = useRoute().params.siteId as string;

const siteName = ref<string | undefined>(undefined);
const paymentMethods = ref<({ updateLoading?: boolean } & PaymentMethod)[] | undefined>(undefined);
const payoutSettings = ref<PayoutSettings[] | undefined>(undefined);
const transferInstruments = ref<TransferInstrument[] | undefined>([]);
const fetchedSettings = ref<Settings | undefined>(undefined);
onMounted(async () => {
  const internalSiteSettings = await api!.siteInternalSettings.getSiteSettings(siteId);
  siteName.value = internalSiteSettings.name;
  paymentMethods.value = internalSiteSettings.paymentMethods;
  payoutSettings.value = internalSiteSettings.payoutSettings;
  transferInstruments.value = internalSiteSettings.transferInstruments;
  fetchedSettings.value = internalSiteSettings.internalSettings;
  uiSettings.value = {
    tips: {
      enabled: !!fetchedSettings.value?.tips?.provider,
      provider: fetchedSettings.value?.tips?.provider || '',
      tipjarCode: fetchedSettings.value?.tips?.tipjarShortcode || '',
      skipTipsIfServiceChargeIncluded: fetchedSettings.value?.tips?.skipTipsIfServiceChargeIncluded || false,
    },
  };
});

const updateLoading = ref(false);

const updateInternalSettings = async () => {
  updateLoading.value = true;
  try {
    await api!.siteInternalSettings.updateSiteSettings(siteId, updatedSettings.value);
    fetchedSettings.value = updatedSettings.value;
    applicationStore.notifyUser({ message: 'Internal settings for site saved' });
  } catch (error) {
    console.error('Failed to save internal settings for site', error);
    applicationStore.notifyUser({ message: 'Failed to save internal settings for site', type: 'error' });
  } finally {
    updateLoading.value = false;
  }
};

const uiSettings = ref({
  tips: {
    enabled: false,
    provider: '',
    tipjarCode: '',
    skipTipsIfServiceChargeIncluded: false,
  },
});
const rules = {
  tips: {
    enabled: {},
    provider: { required: requiredIf(() => uiSettings.value.tips.enabled) },
    tipjarCode: { required: requiredIf(() => uiSettings.value.tips.enabled && uiSettings.value.tips.provider === 'tipjar') },
  },
};
const v$ = useVuelidate(rules, uiSettings);
watch(
  () => uiSettings.value.tips.provider,
  (newValue) => {
    if (newValue === 'yetipay') {
      uiSettings.value.tips.tipjarCode = '';
    }
  },
);
const updatedSettings = computed<Settings>(() => ({
  tips:
    (uiSettings.value.tips.enabled && {
      provider: uiSettings.value.tips.provider as 'tipjar' | 'yetipay',
      tipjarShortcode: uiSettings.value.tips.tipjarCode === '' ? undefined : uiSettings.value.tips.tipjarCode,
      skipTipsIfServiceChargeIncluded: uiSettings.value.tips.skipTipsIfServiceChargeIncluded || undefined,
    }) ||
    undefined,
}));
const settingsChanged = computed(() => JSON.stringify(updatedSettings.value) !== JSON.stringify(fetchedSettings.value));
const tipsProviders = ref([
  { title: 'TipJar', value: 'tipjar' },
  { title: 'Yetipay', value: 'yetipay' },
]);

const togglePaymentMethodStatus = async (paymentMethodId: string) => {
  const paymentMethod = paymentMethods.value!.find((method) => method.id === paymentMethodId);
  if (!paymentMethod) {
    applicationStore.notifyUser({ message: `Failed to toggle update method ${paymentMethodId} status`, type: 'error' });
    return;
  }
  paymentMethod.updateLoading = true;
  try {
    await api!.siteInternalSettings.updatePaymentMethod(siteId, paymentMethodId, !paymentMethod.enabled);
    paymentMethod.enabled = !paymentMethod.enabled;
    applicationStore.notifyUser({ message: `Payment method ${paymentMethod.name} ${paymentMethod.enabled ? 'enabled' : 'disabled'}` });
  } catch (error) {
    console.error(`Failed to toggle payment method ${paymentMethod.name} status`, error);
    applicationStore.notifyUser({ message: `Failed to toggle update method ${paymentMethod.name} status`, type: 'error' });
    captureException(error);
  } finally {
    paymentMethod.updateLoading = false;
  }
};
</script>
