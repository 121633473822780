import { defineStore } from 'pinia';
import { useMerchantsStore } from './merchants';
import BillingApi, { PaymentMethod } from '@/api/merchant-service/billing';

const api = new BillingApi(() => useMerchantsStore().currentMerchant!.merchantId);

export enum LOADING_STATES {
  NOT_LOADED = 'NOT_LOADED',
  ERROR = 'ERROR',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
}

type State = {
  billingSettingsLoadStatus: LOADING_STATES;
  createCheckoutSessionLoadStatus: LOADING_STATES;
  stripeCustomerId: string;
  paymentMethods: PaymentMethod[];
  defaultPaymentMethodId?: string;
  hasRates: boolean;
  hasRatesSigned: boolean;
  signRatesUrl?: string;
};

const state = (): State => ({
  billingSettingsLoadStatus: LOADING_STATES.NOT_LOADED,
  createCheckoutSessionLoadStatus: LOADING_STATES.NOT_LOADED,
  stripeCustomerId: '',
  paymentMethods: [],
  hasRates: false,
  hasRatesSigned: false,
});

const getters = {
  hasPaymentMethods(this: State) {
    return this.paymentMethods.length > 0;
  },
  showNoPaymentMethodsWarning(this: State) {
    return this.paymentMethods.length === 0 && this.billingSettingsLoadStatus === LOADING_STATES.LOADED;
  },
};

const fetchBillingSettings = async ($this: State) => {
  $this.billingSettingsLoadStatus = LOADING_STATES.LOADING;
  try {
    const billingSettings = await api.fetchBillingSettings();
    $this.paymentMethods = billingSettings.paymentMethods;
    $this.defaultPaymentMethodId = billingSettings.defaultPaymentMethod;
    $this.stripeCustomerId = billingSettings.stripeCustomerId;
    $this.billingSettingsLoadStatus = LOADING_STATES.LOADED;
    $this.hasRates = billingSettings.hasRates;
    $this.hasRatesSigned = billingSettings.hasSignedRates;
    $this.signRatesUrl = billingSettings.ratesSignUrl;
  } catch (error) {
    $this.billingSettingsLoadStatus = LOADING_STATES.ERROR;
    console.error('Error fetching billing settings', error);
  }
};

const actions = {
  async initialize(this: State) {
    await fetchBillingSettings(this);
  },
  async createCheckoutSession(this: State) {
    this.createCheckoutSessionLoadStatus = LOADING_STATES.LOADING;
    try {
      const response = await api.createCheckoutSession();
      this.createCheckoutSessionLoadStatus = LOADING_STATES.LOADED;
      return { paymentToken: response.paymentToken, stripePublicKey: response.stripePublicKey };
    } catch (error) {
      this.createCheckoutSessionLoadStatus = LOADING_STATES.ERROR;
      console.error('Error creating checkout session', error);
    }
  },
};

export const useBillingStore = defineStore('billing', { state, getters, actions });
