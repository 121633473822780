import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';

import { VBtn } from 'vuetify/components/VBtn';

import { createVuetify } from 'vuetify';

export default createVuetify({
  theme: {
    themes: {
      light: {
        colors: {
          primary: '#3C038C',
          'on-primary': '#FCFAFF',
          secondary: '#E34696',
          'on-secondary': '#FEFBFC',
          error: '#930009',
          'on-error': '#FFFAFA',
          success: '#31683D',
          'on-success': '#FCFDFC',
          background: '#FDFCFC',
          'on-background': '#1F141A',
          'on-background-variant': '#4A3D5C',
          outline: '#7B6699',
          'outline-variant': '#CAC2D6',
          'on-outline-variant': '#EBE0E6',
        },
      },
    },
  },
  aliases: {
    VBtnVar: VBtn,
    VBtnNd: VBtn,
  },
  defaults: {
    VBtn: {
      color: 'primary',
      size: 'large',
      rounded: 'sm',
    },
    VBtnVar: {
      color: 'background',
      class: 'text-primary',
      size: 'large',
      rounded: 'sm',
    },
    VBtnNd: {
      color: 'secondary',
      size: 'large',
      rounded: 'sm',
      variant: 'outlined',
    },
    VSkeletonLoader: {
      color: 'background',
    },
    VDataTable: {
      style: 'background-color: rgb(var(--v-theme-background));',
    },
    VTable: {
      style: 'background-color: rgb(var(--v-theme-background));',
    },
    VExpansionPanel: {
      style: 'background-color: rgb(var(--v-theme-background));',
    },
  },
});
