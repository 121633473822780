<template>
  <div class="d-flex flex-column">
    <v-btn-nd class="ms-auto" width="50px" variant="text" @click="refresh"><v-icon>mdi-refresh</v-icon></v-btn-nd>
    <v-infinite-scroll :items="reports" color="primary" @load="load">
      <template v-for="item in reports" :key="item.reportId">
        <v-card class="my-2 pa-3 d-flex align-center">
          <v-chip v-if="!xs" :color="item.state === 'READY' ? 'success' : 'default'" :variant="item.state === 'READY' ? 'tonal' : 'plain'" class="mr-2">
            {{ item.state }}
          </v-chip>
          <div>
            <kbd>{{ item.fileName }}</kbd
            ><br />
            <span class="font-weight-thin font-italic text-caption">Requested {{ date.format(item.requestedAt, 'fullDateTime') }}</span>
          </div>
          <v-spacer />
          <div class="d-flex flex-column justify-center">
            <v-chip v-if="xs" :color="item.state === 'READY' ? 'success' : 'default'" :variant="item.state === 'READY' ? 'tonal' : 'plain'" class="mb-2">
              {{ item.state }}
            </v-chip>
            <v-btn-nd color="secondary" size="default" :disabled="item.state !== 'READY'" :loading="!!downloading[item.reportId]" @click="download(item)">
              <v-icon>mdi-download</v-icon>
            </v-btn-nd>
          </div>
        </v-card>
      </template>
      <template #empty>
        <span v-if="(reports?.length ?? 0) > 0 && !lastKey">No more reports</span>
        <span v-else-if="(reports?.length ?? -1) === 0">
          No reports have been generated. You can generate a new report from the <router-link to="/transactions">Transactions page</router-link>.
        </span>
      </template>
    </v-infinite-scroll>
    <DownloadDialog v-model="reportDownloadDialog" :report-id="reportId" />
  </div>
</template>

<script setup lang="ts">
import ReportApi, { Report } from '@/api/merchant-service/report';
import { API } from '@/plugins/api';
import { onMounted, ref, watch } from 'vue';
import { inject } from 'vue';
import { useDisplay } from 'vuetify';
import { useRoute, useRouter } from 'vue-router';
import { useDate } from 'vuetify';

const api = inject<API>('api');
const { xs } = useDisplay();
const route = useRoute();
const router = useRouter();
const date = useDate();

const reports = ref<Report[] | undefined>(undefined);
const lastKey = ref<string | undefined>(undefined);
const downloading = ref<{ [key: string]: boolean }>({});
const reportDownloadDialog = ref(false);
const reportId = ref<string | undefined>(undefined);

onMounted(() => {
  if (route.params.reportId) {
    reportId.value = route.params.reportId as string;
    reportDownloadDialog.value = true;
  }
});
watch(reportDownloadDialog, (value: boolean, oldValue: boolean) => {
  if (oldValue && !value) {
    router.push({ name: 'reports' }); // so that a refresh doesn't retrigger a download
  }
});

const load = async (load: { side: string; done: (status: 'loading' | 'error' | 'empty' | 'ok') => void }) => {
  if (reports.value && !lastKey.value) {
    load.done('empty');
    return;
  }
  try {
    const response = await api!.report.listReports(lastKey.value);
    lastKey.value = response.lastKey;
    if (!reports.value) {
      reports.value = [];
    }
    reports.value.push(...response.data);
    load.done('ok');
  } catch (e) {
    console.error(e);
    load.done('error');
  }
};

const refresh = async () => {
  reports.value = undefined;
  lastKey.value = undefined;
  await load({ side: 'bottom', done: () => {} });
};

const download = async (report: Report) => {
  downloading.value[report.reportId] = true;
  try {
    const response = await api!.report.presignedUrl(report.reportId);
    await ReportApi.downloadReport(response.presignedUrl, response.fileName);
  } catch (e) {
    console.error(e);
  } finally {
    downloading.value[report.reportId] = false;
  }
};
</script>
