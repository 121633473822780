<template>
  <v-dialog max-width="500">
    <template #default="{ isActive }">
      <v-card>
        <template #title><span class="text-primary">Add company</span></template>
        <template #text>
          <p>You are about to create a new legal entity.</p>
          <br />
          <p>You'll need to complete the onboarding process, including legal verification checks and contracts signing for the company you're adding.</p>
          <br />
          <p>Start onboarding journey for new company now?</p>
        </template>
        <template #actions>
          <v-btn-nd text="Cancel" class="ms-4 my-4" @click="isActive.value = false" />
          <v-spacer></v-spacer>
          <v-btn text="Start" to="/onboarding" class="me-4 my-4 bg-primary on-primary" @click="merchantsStore.startNewMerchant" />
        </template>
      </v-card>
    </template>
  </v-dialog>
</template>

<script setup lang="ts">
import { useMerchantsStore } from '@/store/merchants';

const merchantsStore = useMerchantsStore();
</script>
