<template>
  <v-footer absolute app color="background">
    <v-spacer />
    <v-btn v-for="link in links" :key="link.link" variant="text" size="small" class="on-background" :href="link.link" target="_blank">
      {{ link.name }}
    </v-btn>
    <v-btn variant="text" size="small" class="on-background" @click="$CookieConsent.showPreferences()">Cookies</v-btn>
    <v-spacer />
  </v-footer>
</template>

<script setup lang="ts">
import { inject, reactive } from 'vue';

const links = reactive([
  { name: 'Terms', link: 'https://yetipay.me/terms' },
  { name: 'Privacy', link: 'https://yetipay.me/privacy' },
]);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const $CookieConsent = inject<any>('CookieConsent');
</script>
