<template>
  <v-col cols="12" md="6">
    <v-card class="pa-5 mt-12">
      <v-card-title>
        <h2 class="font-weight-medium">Create password</h2>
      </v-card-title>
      <v-card-text class="mt-5">Welcome to Yetipay! To continue, please update your password.</v-card-text>
      <v-card-text>
        <v-form @submit.prevent="forceNewPassword">
          <!-- Hidden username field for accessibility -->
          <!-- https://www.chromium.org/developers/design-documents/create-amazing-password-forms/ -->
          <v-text-field id="username" style="display: none" type="email" value="user@example.com"></v-text-field>

          <v-text-field
            v-model="newPassword"
            placeholder="Password"
            required
            persistent-placeholder
            density="compact"
            type="password"
            autocapitalize="none"
            autocomplete="new-password"
            :error-messages="(v$.newPassword.$dirty && v$.newPassword.$invalid && v$.newPassword.$silentErrors[0].$message.toString()) || ''"
            @input="authStore.resetErrorMessages"
          ></v-text-field>
          <v-text-field
            v-model="repeatNewPassword"
            placeholder="re-enter Password"
            required
            persistent-placeholder
            density="compact"
            type="password"
            autocapitalize="none"
            autocomplete="repeat-new-password"
            :error-messages="(v$.repeatNewPassword.$dirty && v$.repeatNewPassword.$invalid && v$.repeatNewPassword.$silentErrors[0].$message.toString()) || ''"
            @input="authStore.resetErrorMessages"
          ></v-text-field>
          <v-btn :disabled="isSubmitFormDisabled" class="mt-5" type="submit" block>Continue</v-btn>
          <v-alert v-if="authStore.forceNewPassword.errorMessage" density="compact" outlined type="warning" class="mt-4">
            {{ authStore.forceNewPassword.errorMessage }}
          </v-alert>
        </v-form>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, sameAs } from '@vuelidate/validators';
import { useAuthenticationStore, LOADING_STATES } from '@/store/authentication';
import { passwordValidations } from '@/utils/custom-validators';

const emit = defineEmits<{
  submit: [{ newPassword: string }];
  success: [];
  failure: [];
}>();
const newPassword = ref('');
const repeatNewPassword = ref('');

const authStore = useAuthenticationStore();

const v$ = useVuelidate(
  {
    newPassword: {
      $autoDirty: true,
      ...passwordValidations,
    },
    repeatNewPassword: {
      required,
      $autoDirty: true,
      sameAsPassword: sameAs(newPassword),
    },
  },
  { newPassword, repeatNewPassword },
);

const isSubmitFormDisabled = computed(() => v$.value.$invalid || authStore.forceNewPassword.status !== LOADING_STATES.IDLE);

const forceNewPassword = async () => {
  emit('submit', { newPassword: newPassword.value });
  const forceNewPasswordSuccess = await authStore.initForceNewPassword({ newPassword: newPassword.value });
  if (forceNewPasswordSuccess) {
    emit('success');
    return;
  }
  emit('failure');
};
</script>
