import { myAxios } from '@/plugins/api';
import { Role } from '@/store/authorization';

export interface User {
  id: string;
  email: string;
  firstName?: string;
  lastName?: string;
  companyRoles: Role[];
  siteRoles: { siteId: string; role: Role }[];
}

export type UserInvite = Omit<User, 'id'>;
export type UserUpdate = Omit<User, 'email'>;

class UserApi {
  private readonly merchantId: () => string;
  constructor(merchantId: () => string) {
    this.merchantId = merchantId;
  }

  public async listUsers(): Promise<User[]> {
    return myAxios.get(`/v1/merchants/${this.merchantId()}/users`).then((response) => response.data);
  }

  public async inviteUser(user: UserInvite): Promise<void> {
    const body = {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      companyRoles: user.companyRoles,
      siteRoles: user.siteRoles,
    };
    return myAxios.post(`/v1/merchants/${this.merchantId()}/users`, body);
  }

  public async getUser(userId: string): Promise<User> {
    return myAxios.get(`/v1/merchants/${this.merchantId()}/users/${userId}`).then((response) => response.data);
  }

  public async updateUser(user: UserUpdate): Promise<User> {
    const body = {
      firstName: user.firstName,
      lastName: user.lastName,
      companyRoles: user.companyRoles,
      siteRoles: user.siteRoles,
    };
    return myAxios.post(`/v1/merchants/${this.merchantId()}/users/${user.id}`, body);
  }

  public async dissociateUser(userId: string): Promise<void> {
    return myAxios.delete(`/v1/merchants/${this.merchantId()}/users/${userId}`);
  }
}

export default UserApi;
