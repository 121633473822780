import { myAxios } from '@/plugins/api';

export interface PaymentLinkV1 {
  merchantId: string;
  siteId: string;
  linkId: string;
  description: string;
}

class PaymentLinkApi {
  private readonly merchantId: () => string;
  constructor(merchantId: () => string) {
    this.merchantId = merchantId;
  }

  public async getPaymentLinks(linkIds: string[]): Promise<PaymentLinkV1[]> {
    return myAxios.get(`/v1/merchant/${this.merchantId()}/payment-links`, { params: { linkIds: linkIds.join(',') } }).then((response) => response.data.data);
  }
}

export default PaymentLinkApi;
