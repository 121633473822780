<template>
  <v-btn color="background" class="google" width="250" @click="click">
    <img src="@/assets/img/google.png" height="17" style="position: relative; top: 2px" /> {{ buttonText }}
  </v-btn>
</template>

<script setup lang="ts">
import { onBeforeMount, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useDisplay } from 'vuetify';
import { signInWithRedirect, fetchAuthSession } from '@aws-amplify/auth';
import { useAuthenticationStore } from '@/store/authentication';

const props = defineProps({
  flow: {
    type: String,
    required: true,
    validator: (value: string) => ['in', 'up'].includes(value),
  },
});

const authStore = useAuthenticationStore();
const router = useRouter();
const { width } = useDisplay();

const xxs = computed(() => width.value < 330);
const buttonText = computed(() => (xxs.value ? `Sign ${props.flow}` : `Sign ${props.flow} with Google`));

onBeforeMount(async () => {
  const session = await fetchAuthSession();
  if (session.tokens) {
    router.push('/'); // The redirect doesn't work well when not localhost
  }
});

const click = async () => {
  try {
    await signInWithRedirect({ provider: 'Google' });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.message === 'There is already a signed in user.') {
      authStore.signOut();
    } else {
      console.error(error);
    }
  }
};
</script>

<style>
.v-btn.google > span {
  text-transform: none;
}
</style>
