<template>
  <ReviewOfKycDataNotification v-if="merchantsStore.currentMerchantReviewOfDateDeadline" />

  <v-sheet v-if="showTrafficLight" class="d-flex mb-15" color="background">
    <v-spacer />
    <DeviceOrderImpossible
      :missing-payment-method="billingStore.showNoPaymentMethodsWarning"
      :missing-deal="!billingStore.hasRates"
      :deal-not-signed="!billingStore.hasRatesSigned"
      :deal-url="billingStore.signRatesUrl"
      :is-staff="authorizationStore.isStaff"
      :is-legacy-customer="isLegacyCustomer && !billingStore.showNoPaymentMethodsWarning"
      max-width="960"
      @associate-deal-reference="linkDealReference"
    />
    <v-spacer />
  </v-sheet>

  <div v-else class="d-flex justify-space-evenly" :class="smAndDown ? 'flex-column' : ''">
    <v-spacer v-if="hasOrderDevicePermission" />
    <v-hover v-if="hasOrderDevicePermission" v-slot="{ isHovering, props }" open-delay="100">
      <router-link to="/request-new-device" class="text-decoration-none" min-width="200" style="cursor: pointer; flex-grow: 4">
        <v-card :elevation="isHovering ? 16 : 2" align="center" class="py-10 mx-15 my-5 gradient-primary on-primary flex-fill" v-bind="props">
          <v-icon class="text-xl">mdi-cellphone</v-icon><br />
          Order devices
        </v-card>
      </router-link>
    </v-hover>
    <v-spacer />
    <v-hover v-slot="{ isHovering, props }" open-delay="100">
      <router-link to="/transactions" class="text-decoration-none" min-width="200" style="cursor: pointer; flex-grow: 4">
        <v-card :elevation="isHovering ? 16 : 2" align="center" class="py-10 mx-15 my-5 gradient-primary on-primary flex-fill" v-bind="props">
          <v-icon class="text-xl">mdi-credit-card</v-icon><br />
          View transactions
        </v-card>
      </router-link>
    </v-hover>
    <v-spacer />
  </div>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue';
import { useDisplay } from 'vuetify';
import { useRouter } from 'vue-router';
import { useBillingStore } from '@/store/billing';
import { useApplicationStore } from '@/store/application';
import { useMerchantsStore } from '@/store/merchants';
import { Permission, useAuthorizationStore } from '@/store/authorization';
import { API } from '@/plugins/api';

const { smAndDown } = useDisplay();
const router = useRouter();
const billingStore = useBillingStore();
const applicationStore = useApplicationStore();
const merchantsStore = useMerchantsStore();
const authorizationStore = useAuthorizationStore();
const api = inject<API>('api');

const isLegacyCustomer = computed(() => (merchantsStore.currentMerchant?.createdAt ?? '') < '2024-07-17');
const showTrafficLight = computed(
  () =>
    authorizationStore.hasCompanyScoped(Permission.Billing_ListPaymentMethods) &&
    (billingStore.showNoPaymentMethodsWarning || (!isLegacyCustomer.value && !billingStore.hasRates && !billingStore.hasRatesSigned)),
);
const hasOrderDevicePermission = computed(() => authorizationStore.hasCompanyScoped(Permission.Device_OrderDevice));

const linkDealReference = async (dealReference: string) => {
  try {
    await api?.merchant.associateDeal(merchantsStore.currentMerchant!.merchantId, dealReference);
    applicationStore.notifyUser({ message: 'Company linked to deal', type: 'success' });
    router.go(0);
  } catch (error) {
    console.error(error);
    applicationStore.notifyUser({ message: 'Failed to link company to deal', type: 'error' });
  }
};
</script>
