<template>
  <div class="text-center">
    <v-snackbar
      v-model="applicationStore.userNotification.show"
      :timeout="applicationStore.userNotification.timeout"
      :color="applicationStore.userNotification.type"
    >
      {{ applicationStore.userNotification.message }}
    </v-snackbar>
  </div>
</template>

<script setup lang="ts">
import { useApplicationStore } from '@/store/application';

const applicationStore = useApplicationStore();
</script>
