<template>
  <v-pagination v-model="currentPage" :length="lastKeyPages.length - (!lastKeyPages[lastKeyPages.length - 1] ? 1 : 0)" color="secondary" rounded="sm">
    <template #next="{ onClick }">
      <v-btn-nd v-if="lastKeyPages[lastKeyPages.length - 1]" disabled icon="mdi-dots-horizontal" variant="text" size="default" @click="onClick" />
      <v-btn-nd :disabled="!lastKeyPages[currentPage]" icon="mdi-chevron-right" variant="text" size="default" @click="onClick" />
    </template>
  </v-pagination>
</template>

<script setup lang="ts">
const currentPage = defineModel<number>('currentPage', { required: true });
defineProps<{ lastKeyPages: (string | undefined)[] }>();
</script>
