<template>
  <div class="d-flex mt-4">
    <v-icon :class="`${textColor} text-h3 ${margintEnd} align-self-center`">{{ emoji }}</v-icon>
    <div :class="(!success && 'font-weight-bold') + ' align-self-center'">
      <slot></slot> <v-btn v-if="showButton" size="default" v-bind="btnProps">{{ actionText }}</v-btn>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useDisplay } from 'vuetify';

const { success, actionText, actionLink, actionLinkTab } = defineProps<{
  success: boolean;
  actionText?: string;
  actionLink?: string;
  actionLinkTab?: boolean;
}>();

const { xs } = useDisplay();

const emoji = computed(() => (success ? 'mdi-emoticon-cool-outline' : 'mdi-emoticon-dead-outline'));
const textColor = computed(() => (success ? 'text-success' : 'text-error'));
const margintEnd = computed(() => (xs.value ? 'me-2' : 'me-10'));
const showButton = computed(() => !success && actionText && actionLink);

const btnProps = computed(() => (actionLinkTab ? { href: actionLink, target: '_blank' } : { to: actionLink }));
</script>
