<template>
  <div align="center" class="on-secondary">
    <h1 class="my-15">{{ pages[pageInUse].title }}</h1>
    <v-img v-if="pageInUse === '404'" src="@/assets/img/searching-yeti.png" width="200px" class="mx-auto d-block" />
    <v-img v-else src="@/assets/img/yeti-not-right.png" width="200px" class="mx-auto d-block" />
    <p class="my-15 font-italic">{{ pages[pageInUse].message }}</p>
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, ref } from 'vue';
import { useRoute } from 'vue-router';

const pages: Record<string, { title: string; message: string }> = {
  '404': {
    title: 'Did you get lost?',
    message: "The page you tried to access doesn't exist",
  },
  'not right': {
    title: "Something's not right",
    message: "That didn't quite work",
  },
};

const pageInUse = ref('not right');

onBeforeMount(() => {
  switch (useRoute().name) {
    case '404':
      pageInUse.value = '404';
      break;
  }
});
</script>
