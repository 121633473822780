<template>
  <v-card class="fill-height">
    <v-card-title>
      <h3 class="d-flex justify-space-between headline mb-0">
        <div>{{ sanitizeMethodType(method.type) }}</div>
        <v-chip v-if="isDefault" color="blue" label>DEFAULT</v-chip>
      </h3>
      <div>Added: {{ format(new Date(method.created * 1000), 'd MMMM yyyy') }}</div>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <div class="d-flex flex-column justify-space-between" :style="showActions ? 'height: 180px' : 'height: 120px'">
        <div>
          <!-- display fields for sepa debit method -->
          <div v-if="method.type === 'sepa_debit'">
            <div class="font-weight-black">Name:</div>
            {{ method.billing_details.name }}
            <div class="font-weight-black">Country:</div>
            {{ method.sepa_debit!.country }}
            <div class="font-weight-black">Card Ending:</div>
            {{ method.sepa_debit!.last4 }}
          </div>

          <!-- display fields for bacs debit method -->
          <div v-else-if="method.type === 'bacs_debit'">
            <div class="font-weight-black">Name:</div>
            {{ method.billing_details.name }}
            <div class="font-weight-black">Sort Code:</div>
            {{ method.bacs_debit!.sort_code }}
          </div>

          <!-- display fields for card debit method -->
          <div v-else-if="method.type === 'card'">
            <div>Card Type: {{ sanitizeMethodType(method.card!.brand) }}</div>
            <div class="font-weight-black">Country: {{ method.card!.country }}</div>
            <div class="font-weight-black">Exp: {{ method.card!.exp_month }}/{{ method.card!.exp_year }}</div>
            <div class="font-weight-black">Card Ending:</div>
            {{ method.card!.last4 }}
          </div>
        </div>

        <div v-if="showActions" class="d-flex justify-space-between align-center">
          <v-icon
            v-bind="activatorProps"
            color="error"
            size="x-large"
            :disabled="isDefault"
            @click="emit('action', { action: 'delete', paymentMethodId: method.id })"
          >
            mdi-delete-outline
          </v-icon>
          <v-btn-nd v-bind="activatorProps" :disabled="isDefault" @click="emit('action', { action: 'default', paymentMethodId: method.id })">
            Make default
          </v-btn-nd>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { PaymentMethod } from '@/api/merchant-service/billing';
import { format } from 'date-fns';

defineProps<{
  method: PaymentMethod;
  isDefault?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  activatorProps?: Record<string, any>;
  showActions?: boolean;
}>();
const emit = defineEmits<{ action: [{ action: 'default' | 'delete'; paymentMethodId: string }] }>();

const sanitizeMethodType = (value: string) => {
  if (!value) return '';
  return value
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};
</script>
