import { createRouter, createWebHistory } from 'vue-router';
import multiguard from 'vue-router-multiguard';

import {
  initialiseApplication,
  userMustBeAuthenticated,
  userMustNotBeAuthenticated,
  merchantMustBeVerified,
  merchantMustNotBeVerified,
  userMustBeStaff,
  userMustBeConfigured,
  requiredSitePermissions,
  requiredCompanyPermissions,
  canManageCompanies,
} from '@/router/navigation-guards';

import 'vue-router';
import { Permission } from '@/store/authorization';

declare module 'vue-router' {
  interface RouteMeta {
    breadcrumbs?: string[];
  }
}

const routes = [
  {
    path: '/public',
    component: () => import('@/layouts/Full.vue'),
    children: [
      {
        path: '/signin',
        name: 'signin',
        beforeEnter: multiguard([userMustNotBeAuthenticated]),
        component: () => import('@/views/Authentication/SignIn.vue'),
      },
      {
        path: '/signup',
        name: 'signup',
        beforeEnter: multiguard([userMustNotBeAuthenticated]),
        component: () => import('@/views/Authentication/SignUp.vue'),
      },
      {
        path: '/forgot',
        name: 'forgot-password',
        beforeEnter: multiguard([userMustNotBeAuthenticated]),
        component: () => import('@/views/Authentication/ForgotPassword.vue'),
      },
      {
        path: '/oops',
        name: 'not right',
        component: () => import('@/views/NotRight.vue'),
      },
    ],
  },
  {
    path: '/app',
    component: () => import('@/layouts/App.vue'),
    beforeEnter: multiguard([userMustBeAuthenticated, initialiseApplication, merchantMustBeVerified, userMustBeConfigured]),
    children: [
      {
        path: '/',
        name: 'your basecamp',
        component: () => import('@/views/AppLanding.vue'),
        meta: { hideBillingWarningBanner: true },
      },
      {
        path: '/billing/payment-methods',
        name: 'payment methods',
        component: () => import('@/views/Billing/BillingSettings.vue'),
        meta: { breadcrumbs: ['Billing', 'Payment methods'] },
        beforeEnter: multiguard([requiredCompanyPermissions([Permission.Billing_ListPaymentMethods])]),
      },
      {
        path: '/billing/payment-methods/new',
        name: 'add payment method',
        component: () => import('@/views/Billing/AddPaymentMethod.vue'),
        meta: {
          breadcrumbs: ['Billing', 'Payment methods', 'New'],
          hideBillingWarningBanner: true,
        },
        beforeEnter: multiguard([requiredCompanyPermissions([Permission.Billing_AddPaymentMethod])]),
      },
      {
        path: '/billing/invoices',
        name: 'invoices',
        component: () => import('@/views/Billing/Invoices.vue'),
        meta: { breadcrumbs: ['Billing', 'Invoices'] },
        beforeEnter: multiguard([requiredCompanyPermissions([Permission.Billing_ListInvoices])]),
      },
      {
        path: '/devices',
        name: 'all devices',
        component: () => import('@/views/Devices/Devices.vue'),
        meta: { breadcrumbs: ['Devices'] },
      },
      {
        path: '/request-new-device',
        name: 'order new device',
        component: () => import('@/views/Devices/NewDeviceRequestWizard.vue'),
        meta: {
          breadcrumbs: ['Devices', 'Order new'],
          hideBillingWarningBanner: true,
        },
        beforeEnter: multiguard([requiredCompanyPermissions([Permission.Device_OrderDevice])]),
      },
      {
        path: '/sites',
        name: 'all sites',
        component: () => import('@/views/Sites/Sites.vue'),
        meta: { breadcrumbs: ['Sites'] },
      },
      {
        path: '/sites/:siteId',
        name: 'edit site',
        component: () => import('@/views/Sites/EditSite.vue'),
        meta: { breadcrumbs: ['Sites', 'Edit site'] },
        beforeEnter: multiguard([requiredSitePermissions([Permission.Site_UpdateSite])]),
      },
      {
        path: '/sites/:siteId/internal',
        name: 'site internal settings',
        component: () => import('@/views/Sites/InternalSettings.vue'),
        meta: { breadcrumbs: ['Sites', 'Internal Settings'] },
        beforeEnter: multiguard([userMustBeStaff]),
      },
      {
        path: '/new-site',
        name: 'new site',
        component: () => import('@/views/Sites/NewSite.vue'),
        meta: { breadcrumbs: ['Sites', 'Create new site'] },
        beforeEnter: multiguard([requiredCompanyPermissions([Permission.Site_CreateSite])]),
      },
      {
        path: '/transactions',
        name: 'transactions',
        component: () => import('@/views/Transactions.vue'),
        meta: { breadcrumbs: ['Reporting', 'Transactions'] },
      },
      {
        path: '/payouts',
        name: 'payouts',
        component: () => import('@/views/Payouts/Payouts.vue'),
        meta: { breadcrumbs: ['Reporting', 'Payouts'] },
      },
      {
        path: '/payouts/:payoutId',
        name: 'payout details',
        component: () => import('@/views/Payouts/PayoutLedger.vue'),
        meta: { breadcrumbs: ['Reporting', 'Payouts', 'Ledger'] },
      },
      {
        path: '/reports',
        name: 'reports',
        component: () => import('@/views/Reports.vue'),
        meta: { breadcrumbs: ['Reporting', 'Reports'] },
      },
      {
        path: '/reports/:reportId/download',
        name: 'reports ', // Need the space to avoid conflict with the other reports route
        component: () => import('@/views/Reports.vue'),
        meta: { breadcrumbs: ['Reporting', 'Reports'] },
      },
      {
        path: '/users',
        name: 'Users',
        component: () => import('@/views/Users/ManageUsers.vue'),
        meta: { breadcrumbs: ['Users'] },
        beforeEnter: multiguard([requiredCompanyPermissions([Permission.User_ListUsers])]),
      },
      {
        path: '/users/:userId',
        name: 'Edit user',
        component: () => import('@/views/Users/AddEditUser.vue'),
        meta: { breadcrumbs: ['Users', 'Edit user'] },
        beforeEnter: multiguard([requiredCompanyPermissions([Permission.User_UpdateUser])]),
      },
      {
        path: '/users/add',
        name: 'Add user',
        component: () => import('@/views/Users/AddEditUser.vue'),
        meta: { breadcrumbs: ['Users', 'Add user'] },
        beforeEnter: multiguard([requiredCompanyPermissions([Permission.User_InviteUser])]),
      },
      {
        path: '/company-settings',
        name: 'company settings',
        component: () => import('@/views/Companies/CompanySettings.vue'),
        meta: { breadcrumbs: ['Company settings'] },
        beforeEnter: multiguard([canManageCompanies]),
      },
      {
        path: '/companies',
        name: 'manage companies',
        component: () => import('@/views/Companies/ListCompanies.vue'),
        meta: {
          breadcrumbs: ['Companies'],
          hideBillingWarningBanner: true,
        },
        beforeEnter: multiguard([canManageCompanies]),
      },
    ],
  },
  {
    path: '/onboarding',
    component: () => import('@/layouts/Full.vue'),
    beforeEnter: multiguard([userMustBeAuthenticated, initialiseApplication, merchantMustNotBeVerified]),
    children: [
      {
        path: '/onboarding/pending',
        name: 'invited-pending',
        component: () => import('@/views/Onboarding/InvitedPending.vue'),
      },
      {
        path: '/onboarding',
        name: 'onboarding-landing',
        component: () => import('@/views/Onboarding/Landing.vue'),
        multiguard: [userMustBeConfigured],
      },
      {
        path: '/onboarding/wizard',
        name: 'onboarding-wizard',
        component: () => import('@/views/Onboarding/Wizard.vue'),
        multiguard: [userMustBeConfigured],
      },
    ],
  },
  {
    path: '/:path(.*)*',
    component: () => import('@/layouts/Full.vue'),
    children: [
      {
        path: '',
        name: '404',
        component: () => import('@/views/NotRight.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
