import { defineStore } from 'pinia';
import { useMerchantsStore } from '@/store/merchants';
import { useBillingStore } from '@/store/billing';
import { useAuthenticationStore } from '@/store/authentication';
import { useAuthorizationStore } from '@/store/authorization';

export enum LOADING_STATES {
  NOT_LOADED = 'NOT_LOADED',
  ERROR = 'ERROR',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
}

type ApplicationState = {
  initialiseLoadState: LOADING_STATES;
  userNotification: {
    show: boolean;
    message: string;
    type: 'error' | 'success';
    timeout: number;
  };
};

const state = (): ApplicationState => ({
  initialiseLoadState: LOADING_STATES.NOT_LOADED,
  userNotification: {
    show: false,
    message: '',
    type: 'success',
    timeout: 5000,
  },
});

const getters = {
  isApplicationLoading: (state: ApplicationState) => {
    return state.initialiseLoadState == LOADING_STATES.LOADING;
  },
};

const actions = {
  reset(this: ApplicationState) {
    this.initialiseLoadState = LOADING_STATES.NOT_LOADED;
    useMerchantsStore().$reset();
    useBillingStore().$reset();
    useAuthenticationStore().$reset();
    useAuthorizationStore().$reset();
  },
  async initialize(this: ApplicationState) {
    this.initialiseLoadState = LOADING_STATES.LOADING;
    const authenticationStore = useAuthenticationStore();
    const merchantsStore = useMerchantsStore();
    await authenticationStore.initialise();
    if (!authenticationStore.user.isAuthenticated || !authenticationStore.user.isConfigured) {
      this.initialiseLoadState = LOADING_STATES.LOADED;
      return { redirect: '/onboarding/pending' };
    }

    await merchantsStore.initialize(false);
    if (!merchantsStore.currentMerchant) {
      this.initialiseLoadState = LOADING_STATES.LOADED;
      return { redirect: '/onboarding' };
    }

    this.initialiseLoadState = LOADING_STATES.LOADED;
    return { redirect: false };
  },
  notifyUser(this: ApplicationState, { message, type = 'success' }: { message: string; type?: 'error' | 'success' }) {
    this.userNotification.show = false;
    this.userNotification.message = message;
    this.userNotification.type = type;
    this.userNotification.show = true;
  },
};

export const useApplicationStore = defineStore('application', {
  state,
  getters,
  actions,
});
