<template>
  <SiteForm
    v-model="site"
    mode="create"
    :is-primary-loading="loadingSubmit"
    :primary-error="submitError"
    :merchant-country="merchantsStore.currentMerchant!.country"
    @primary="formSubmitted"
  />
</template>

<script setup lang="ts">
import { ref, inject } from 'vue';
import { useRouter } from 'vue-router';
import { useApplicationStore } from '@/store/application';
import { useMerchantsStore } from '@/store/merchants';
import { API } from '@/plugins/api';
import { Site } from '@/api/merchant-service/site';

const router = useRouter();
const applicationStore = useApplicationStore();
const merchantsStore = useMerchantsStore();

const api = inject<API>('api');

const site = ref<Site>({
  name: '',
  phoneNumber: '',
  address: {
    line1: '',
    city: '',
    postalCode: '',
    country: '',
  },
} as Site);
const loadingSubmit = ref(false);
const submitError = ref<string | undefined>(undefined);

const formSubmitted = async () => {
  submitError.value = undefined;
  loadingSubmit.value = true;
  try {
    await api!.site.createNewSite(site.value!);
    applicationStore.notifyUser({ message: 'Site created' });
    router.push('/sites');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    const message = error.response.data.error;
    submitError.value = message ?? 'An error ocurred when processing this request';
  } finally {
    loadingSubmit.value = false;
  }
};
</script>
