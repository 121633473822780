<template>
  <v-alert v-if="paymentAddErrorMessage" type="error" color="error" border="top">
    <v-row align="center">
      <v-col class="grow">{{ paymentAddErrorMessage }}</v-col>
      <v-col class="shrink">
        <v-btn-var to="/billing/payment-methods/new">Try again</v-btn-var>
      </v-col>
    </v-row>
  </v-alert>
  <v-alert v-else-if="paymentAddSuccessMessage" outlined dismissible type="success" color="green" class="bg-white black--text">
    {{ paymentAddSuccessMessage }}
  </v-alert>

  <div class="d-flex flex-row flex-wrap justify-space-between">
    <p class="mb-5 me-5" style="max-width: 740px">
      Manage your billing payment methods. We recommend at least two active payment methods. Need help? Contact
      <a href="mailto:help@yetipay.me">help@yetipay.me</a>.
    </p>
    <div><v-btn to="/billing/payment-methods/new" prepend-icon="mdi-plus">Add new payment method</v-btn></div>
  </div>

  <BillingMethodList />
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();

const paymentAddSuccessMessage = ref('');
const paymentAddErrorMessage = ref('');

const setupPaymentMethodRedirect = () => {
  const { redirect_status } = route.query;

  if (redirect_status === 'succeeded') {
    paymentAddSuccessMessage.value = 'Payment method added successfully!';
  } else {
    console.error('Payment add redirect error');
    paymentAddErrorMessage.value =
      'Something went wrong, we have not been able to add this payment method to your account. \
If this issue persists, please contact yetipay support or try an alternative payment method.';
  }
  router.replace({ query: undefined });
};

onMounted(() => {
  const isRedirect = route.query.redirect;
  if (isRedirect) {
    setupPaymentMethodRedirect();
  }
});
</script>
