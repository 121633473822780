import { myAxios } from '@/plugins/api';
import axios from 'axios';

export interface Report {
  reportId: string;
  state: 'NEW' | 'PROCESSING' | 'READY' | 'ERROR';
  fileName: string;
  requestedAt: string;
}

class ReportApi {
  private readonly merchantId: () => string;
  constructor(merchantId: () => string) {
    this.merchantId = merchantId;
  }

  public async listReports(lastKey?: string): Promise<{ data: Report[]; lastKey?: string }> {
    return myAxios.get(`/v1/merchants/${this.merchantId()}/reports`, { params: { lastKey } }).then((response) => response.data);
  }

  public async createTransactionReport(siteId?: string, dates?: string, psp?: string): Promise<string> {
    const body = { dataType: 'transactions', siteId, dates, psp };
    return myAxios.post<{ reportId: string }>(`/v1/merchants/${this.merchantId()}/reports`, body).then((response) => response.data.reportId);
  }

  public async presignedUrl(reportId: string): Promise<{ fileName: string; presignedUrl: string }> {
    return myAxios
      .get<{ fileName: string; presignedUrl: string }>(`/v1/merchants/${this.merchantId()}/reports/${reportId}/download`)
      .then((response) => response.data);
  }

  public static async downloadReport(presignedUrl: string, fileName: string): Promise<void> {
    return axios.get(presignedUrl, { responseType: 'blob' }).then((response) => {
      const blob = response.data;
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
  }
}

export default ReportApi;
