import { myAxios } from '@/plugins/api';

export interface PaymentMethod {
  id: string;
  name: string;
  enabled: boolean;
  currencies: string[];
  verificationStatus: string;
}

export interface PayoutSettings {
  id: string;
  status: 'active' | 'inactive';
  category: 'bank' | 'internal';
  currency: string;
  description: string;
  counterparty: string;
  schedule: {
    cronExpression: string;
  };
}

export interface TransferInstrument {
  id: string;
  accountIdentifier: string;
}

export interface GetSettingsResponse {
  name: string;
  paymentMethods: PaymentMethod[];
  payoutSettings: PayoutSettings[];
  transferInstruments: TransferInstrument[];
  internalSettings: Settings;
}

export interface Settings {
  tips?: {
    provider: 'tipjar' | 'yetipay';
    tipjarShortcode?: string;
    skipTipsIfServiceChargeIncluded?: boolean;
  };
}

export interface SiteConnectConfig {
  connection_id: string;
  site_id: 'string';
  authentication: {
    redacted_token: string;
  };
}

class SiteInternalSettingsApi {
  private readonly merchantId: () => string;

  constructor(merchantId: () => string) {
    this.merchantId = merchantId;
  }

  public async getSiteSettings(siteId: string): Promise<GetSettingsResponse> {
    return myAxios.get<GetSettingsResponse>(`/v1/merchants/${this.merchantId()}/sites/${siteId}/internal`).then((response) => response.data);
  }

  public async updateSiteSettings(siteId: string, request: Settings): Promise<Settings> {
    return myAxios.put(`/v1/merchants/${this.merchantId()}/sites/${siteId}/internal`, request);
  }

  public async fetchIntegrationConfig(siteId: string): Promise<SiteConnectConfig> {
    return myAxios.get(`/v1/merchants/${this.merchantId()}/sites/${siteId}/connect-config`).then((response) => response.data.responseBody);
  }
  public async updatePaymentMethod(siteId: string, paymentMethodId: string, enabled: boolean): Promise<void> {
    return myAxios.put(`/v1/merchants/${this.merchantId()}/sites/${siteId}/payment-methods/${paymentMethodId}`, { enabled });
  }
}

export default SiteInternalSettingsApi;
