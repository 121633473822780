<template>
  <div class="d-flex">
    <v-btn class="mb-5 ms-auto" to="/users/add">
      <template #prepend><v-icon>mdi-plus</v-icon></template>
      Add user
    </v-btn>
  </div>
  <v-data-table :headers="[{ value: 'email' }, { value: 'action' }]" :items="users" :search="search" :custom-filter="searchFilter" :loading="usersLoading">
    <template #top>
      <v-text-field v-model="search" label="Find user" prepend-icon="mdi-magnify" />
    </template>
    <template #headers></template>
    <template #item.email="{ item }">
      {{ userDisplayName(item) }} <v-chip v-if="item.email === authStore.user.email" color="primary" size="small">You</v-chip><br />
      <span v-if="item.companyRoles.includes(Role.CompanyOwner)" class="text-disabled text-caption">Admin</span>
    </template>
    <template #item.action="{ item }">
      <router-link :to="`/users/${item.id}`"><v-icon size="14" color="secondary">mdi-pencil</v-icon></router-link>
    </template>
  </v-data-table>
</template>

<script setup lang="ts">
import { User } from '@/api/merchant-service/users';
import { API } from '@/plugins/api';
import { inject, onMounted, ref } from 'vue';
import { useAuthenticationStore } from '@/store/authentication';
import { Role } from '@/store/authorization';

const authStore = useAuthenticationStore();
const api = inject<API>('api');

const users = ref<User[]>([]);
const usersLoading = ref(false);
const search = ref('');
onMounted(async () => {
  usersLoading.value = true;
  try {
    users.value = (await api!.user.listUsers()).sort((a, b) => a.email.localeCompare(b.email));
  } catch (e) {
    console.error(e);
  } finally {
    usersLoading.value = false;
  }
});

// Vuetify doesn't seem to export InternalItem type, so we have to use any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const searchFilter = (value: string, query: string, item: any) => {
  if (!value) return false;
  const typedItem = item as { raw: User };
  const queryLC = query.toLowerCase();
  return (
    typedItem.raw.email.toLowerCase().includes(queryLC) ||
    (!!typedItem.raw.firstName && typedItem.raw.firstName.toLowerCase().includes(queryLC)) ||
    (!!typedItem.raw.lastName && typedItem.raw.lastName.toLowerCase().includes(queryLC))
  );
};
const userDisplayName = (user: User) => {
  if (user.firstName || user.lastName) {
    let name = '';
    if (user.firstName) name += user.firstName + ' ';
    if (user.lastName) name += user.lastName;
    name += ` (${user.email})`;
    return name;
  } else {
    return user.email;
  }
};
</script>
