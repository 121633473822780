<template>
  <v-table density="compact" hover>
    <template #default>
      <thead>
        <tr>
          <th><!-- Date --></th>
          <th class="text-center font-weight-bold">Payments</th>
          <th class="text-center font-weight-bold">Refunds</th>
          <th class="text-center font-weight-bold">Chargebacks</th>
          <th class="text-center font-weight-bold">Tips</th>
          <th class="text-center font-weight-bold">Misc</th>
          <th class="text-center font-weight-bold">Subtotal</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(content, index) in days" :key="index">
          <td>{{ content.date }}</td>
          <td class="text-center">{{ content.totalProcessed }}</td>
          <td class="text-center">{{ content.totalRefunded }}</td>
          <td class="text-center">{{ content.totalChargeback }}</td>
          <td class="text-center">
            <v-tooltip :text="content.tipTooltip!" :disabled="!content.tipTooltip" location="bottom">
              <template #activator="activator">
                <span v-bind="activator.props">{{ content.tip }} <v-icon v-if="content.tipTooltip" color="primary">mdi-information-outline</v-icon></span>
              </template>
            </v-tooltip>
          </td>
          <td class="text-center">{{ content.totalMisc }}</td>
          <td class="text-center">{{ content.subtotal }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th class="text-center font-weight-bold">Total</th>
          <th class="text-center font-weight-bold">{{ totals.processed }}</th>
          <th class="text-center font-weight-bold">{{ totals.refunded }}</th>
          <th class="text-center font-weight-bold">{{ totals.chargeback }}</th>
          <th class="text-center font-weight-bold">
            <v-tooltip :text="totals.tipTooltip!" :disabled="!totals.tipTooltip" location="bottom">
              <template #activator="activator">
                <span v-bind="activator.props">{{ totals.tip }} <v-icon v-if="totals.tipTooltip" color="primary">mdi-information-outline</v-icon></span>
              </template>
            </v-tooltip>
          </th>
          <th class="text-center font-weight-bold">{{ totals.misc }}</th>
          <th class="text-center font-weight-bold">{{ totals.total }}</th>
        </tr>
      </tfoot>
    </template>
  </v-table>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { Amount } from '@/api/merchant-service/common-types';
import { DailySummary } from '@/api/merchant-service/payout';

const props = defineProps({
  days: {
    type: Array<DailySummary>,
    required: true,
  },
  defaultCurrency: {
    type: String,
    required: true,
  },
});
const days = computed(() =>
  props.days.map((content) => {
    const { tip, tipTooltip } = tipAndTooltip(content.includedTip?.value, content.additionalTip?.value);
    const totalProcessedValue = content.totalProcessed.value + (content.additionalTip?.value ?? 0);
    return {
      // @ts-expect-error overload doesn't match
      date: new Date(content.date).toLocaleDateString('en-UK', contentDateOptions),
      // We deduct included tip from total processed
      totalProcessed: transformAmount({ value: totalProcessedValue, currency: content.totalProcessed.currency }),
      totalRefunded: transformAmount(content.totalRefunded),
      totalChargeback: transformAmount(content.totalChargeback),
      totalMisc: transformAmount(content.totalMisc),
      subtotal: transformAmount(content.subtotal),
      tip,
      tipTooltip,
    };
  }),
);
const totals = computed(() => {
  const includedTip = props.days.reduce((acc, content) => acc + (content.includedTip?.value ?? 0), 0);
  const additionalTip = props.days.reduce((acc, content) => acc + (content.additionalTip?.value ?? 0), 0);
  const { tip, tipTooltip } = tipAndTooltip(includedTip, additionalTip);

  // We deduct included tip from total processed
  const totalProcessed = props.days.reduce((acc, content) => acc + content.totalProcessed.value, 0) + additionalTip;
  const totalRefunded = props.days.reduce((acc, content) => acc + content.totalRefunded.value, 0);
  const totalChargeback = props.days.reduce((acc, content) => acc + content.totalChargeback.value, 0);
  const totalMisc = props.days.reduce((acc, content) => acc + content.totalMisc.value, 0);
  const subtotal = props.days.reduce((acc, content) => acc + content.subtotal.value, 0);
  return {
    processed: transformAmount({ value: totalProcessed, currency: props.defaultCurrency }),
    refunded: transformAmount({ value: totalRefunded, currency: props.defaultCurrency }),
    chargeback: transformAmount({ value: totalChargeback, currency: props.defaultCurrency }),
    tip,
    tipTooltip,
    misc: transformAmount({ value: totalMisc, currency: props.defaultCurrency }),
    total: transformAmount({ value: subtotal, currency: props.defaultCurrency }),
  };
});

const contentDateOptions = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };
const transformAmount = (amount: Amount) => amount.value.toLocaleString('en-UK', { style: 'currency', currency: amount.currency });
const tipAndTooltip = (included?: number, additional?: number) => {
  if ((included ?? 0) > 0 && (additional ?? 0) > 0) {
    console.warn('Payout with both included and additional tips - unexpected!');
    const tipTooltip = "There are tips both within payouts, and directly distributed via Tipjar. This value deduct Tipjar's total from the paid out amount.";
    return { tip: transformAmount({ value: included! - additional!, currency: props.defaultCurrency }), tipTooltip };
  }
  const inc = included && included > 0 ? included : null;
  const add = additional && additional > 0 ? additional : null;
  const tip = transformAmount({ value: inc ?? (add ? add * -1 : null) ?? 0, currency: props.defaultCurrency });
  const tipTooltip =
    included && included > 0
      ? 'Tip amount is included in total amount paid out'
      : additional && additional > 0
        ? 'Tip amount for Tipjar tips is deducted from total paid out. The tip amount is distributed via Tipjar.'
        : null;
  return { tip, tipTooltip };
};
</script>

<style scoped>
.v-table .v-table__wrapper > table > tbody > tr:not(:last-child) > td,
.v-table .v-table__wrapper > table > thead > tr > th {
  border-bottom: none;
}
</style>
