<template>
  <v-card class="mt-5" :subtitle="`ID: ${sweepDetails?.id}`">
    <template #title>
      <v-row class="mb-3">
        <v-col cols="6" md="9">
          <v-chip class="mb-2" :color="isSweepActive ? 'green' : 'red'">Status: {{ sweepDetails?.status.toUpperCase() }}</v-chip>
        </v-col>
        <v-col cols="6" md="3">
          <v-btn
            block
            prepend-icon="mdi-alert"
            :class="isSweepActive ? 'bg-success' : 'bg-error'"
            :loading="isLoading"
            @click="updateSweep({ status: isSweepActive ? 'inactive' : 'active' })"
          >
            {{ isSweepActive ? 'Deactivate' : 'Reactivate' }}
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <v-card-text>
      <div class="text-capitalize"><span class="font-weight-bold">Type:</span> {{ sweepDetails?.category }} transfer</div>
      <div><span class="font-weight-bold">Next Payout:</span> {{ nextRunDateFormatted }}</div>
      <div><span class="font-weight-bold">Currency:</span> {{ sweepDetails?.currency }}</div>
      <div><span class="font-weight-bold">Bank Reference:</span> {{ sweepDetails?.description }}</div>
    </v-card-text>
    <v-card-text>
      <v-form v-if="transferInstruments.length" @submit.prevent="updateSweep({ counterparty: { transferInstrumentId: newCounterparty } })">
        <v-row>
          <v-col cols="12" md="9">
            <v-select
              v-model="newCounterparty"
              :items="transferInstruments"
              item-title="accountIdentifier"
              item-value="id"
              density="compact"
              label="Payout Bank Account"
              outlined
            >
              <template #item="{ props, item }">
                <v-list-item v-bind="props" :subtitle="item.raw.id"></v-list-item>
              </template>
            </v-select>
          </v-col>
          <v-col v-if="newCounterparty !== sweepDetails?.counterparty" cols="12" md="3">
            <v-btn block color="primary" type="submit" :loading="isLoading">Update</v-btn>
          </v-col>
        </v-row>
      </v-form>
      <div v-else>Merchant has no active transfer instruments.</div>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import parser from 'cron-parser';
import { format } from 'date-fns';
import { inject, defineProps, ref, computed } from 'vue';

import { API } from '@/plugins/api';

const api = inject<API>('api');

import { PayoutSettings, TransferInstrument } from '@/api/merchant-service/site-internal-settings';
import { useApplicationStore } from '@/store/application';
import { captureException } from '@sentry/vue';

const { config, transferInstruments, siteId } = defineProps<{
  config: PayoutSettings;
  transferInstruments: TransferInstrument[];
  siteId: string;
}>();

const isLoading = ref<boolean>(false);
const sweepDetails = ref<PayoutSettings | void>(config);

const sweepInstrument = transferInstruments.find((instrument) => instrument.id === sweepDetails.value?.counterparty);
const nextRunDate = new Date(parser.parseExpression(config.schedule.cronExpression).next().toString());
const nextRunDateFormatted = `(${format(nextRunDate, 'EEEE')}) ${nextRunDate.toLocaleString()}`;

const isSweepActive = computed(() => sweepDetails.value?.status === 'active');
const newCounterparty = ref<string | null>(sweepInstrument?.id ?? null);

const updateSweep = async (update: object) => {
  isLoading.value = true;
  try {
    sweepDetails.value = await api?.site.updateSweep(siteId, config.id, update);
    useApplicationStore().notifyUser({ message: 'Payout config update', type: 'success' });
  } catch (error) {
    captureException(error);
    useApplicationStore().notifyUser({ message: 'Failed to update payout config', type: 'error' });
  } finally {
    isLoading.value = false;
  }
};
</script>
