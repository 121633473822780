<template>
  <div class="d-flex justify-center">
    <v-progress-linear v-if="showLoadingSpinner" transition="fade-transition" indeterminate color="success" />
    <div id="stripe-payment-element" style="width: 412px" />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount, defineEmits } from 'vue';
import { useBillingStore, LOADING_STATES } from '@/store/billing';
import { type StripeConstructor, StripeEmbeddedCheckout } from '@stripe/stripe-js';

const emit = defineEmits<{ error: [error: { message: string }] }>();
const billingStore = useBillingStore();
const paymentWidgetTargetDomId = ref('#stripe-payment-element');
const paymentElementConfig = ref<{ paymentToken: string | undefined; stripePublicKey: string | undefined }>({
  paymentToken: undefined,
  stripePublicKey: undefined,
});

const showLoadingSpinner = computed(() => billingStore.createCheckoutSessionLoadStatus === LOADING_STATES.LOADING);

const loadBillingConfig = async () => {
  try {
    const result = await billingStore.createCheckoutSession();
    paymentElementConfig.value.paymentToken = result!.paymentToken;
    paymentElementConfig.value.stripePublicKey = result!.stripePublicKey;
    await renderCheckoutElement();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    emit('error', { message: error.message });
  }
};

declare global {
  interface Window {
    Stripe?: StripeConstructor;
    checkoutInstance?: StripeEmbeddedCheckout;
  }
}

const renderCheckoutElement = async () => {
  const stripeInstance = window.Stripe ? window.Stripe(paymentElementConfig.value.stripePublicKey!) : null;
  if (!stripeInstance) {
    throw new Error('Stripe is not available');
  }

  window.checkoutInstance = await stripeInstance.initEmbeddedCheckout({
    clientSecret: paymentElementConfig.value.paymentToken,
  });
  window.checkoutInstance?.mount(paymentWidgetTargetDomId.value);
};

const tearDownExistingCheckoutElement = () => {
  // Unmount & delete Stripe checkout component reference to prevent duplicates
  if (window.checkoutInstance) {
    window.checkoutInstance?.destroy();
    delete window.checkoutInstance;
  }
};

onMounted(async () => {
  tearDownExistingCheckoutElement();
  await loadBillingConfig();
});

onBeforeUnmount(() => {
  tearDownExistingCheckoutElement();
});
</script>
