import { myAxios } from '@/plugins/api';
import { Permissions } from '@/store/authorization';
import { Site } from './site';

export interface Merchant {
  country: string;
  merchantId: string;
  legalEntityName: string;
  email: string;
  financeEmail: string;
  createdAt: string;
  dateOnboardingComplete: string;
  kycOnboardingStatus: 'verified' | 'pending' | 'action-required';
  kycReviewOfDataDeadline?: string;
  status: 'active' | 'inactive' | 'churned';
  type: 'company' | 'sole-trader';
  selectedIndustries: string[];
  selectedPaymentMethods: string[];
  stripeCustomerId: string;
}
export type MerchantWithPermissions = Merchant & { permissions: Permissions };

export interface Industry {
  value: string;
  name: string;
  status: 'APPROVED' | 'PENDING' | 'NOT_APPROVED';
}

export interface CreateMerchantRequest {
  entity: 'sole-trader' | 'company';
  legalEntityName: string;
  registrationNumber?: string;
  individual?: {
    firstName: string;
    lastName: string;
  };
  industryCode: string;
  registeredAddress: {
    country: string;
  };
  site: Site;
  selectedCurrencies?: string[];
  selectedPaymentMethods: string[];
  dealReference: string;
}

export interface DealRates {
  dealReference: string;
  transactionRates: {
    billingType: 'perCard' | 'fixed' | 'ic';
    domestic: {
      premium: number;
      credit: number;
      debit: number;
      auth: number;
    };
    inter: {
      premium: number;
      credit: number;
      debit: number;
      auth: number;
    };
    intra: {
      premium: number;
      credit: number;
      debit: number;
      auth: number;
    };
    amex: {
      auth: number;
      card: number;
    };
    other: {
      auth: number;
      card: number;
    };
  };
  deviceRate: number;
  settlementRate: number;
  pciRate: number;
  mccRate: number;
  currency: string;
  contractEndDate?: string;
  signed?: boolean;
  signUrl?: string;
}

class MerchantApi {
  public async fetchIndustryCodes(): Promise<Industry[]> {
    return myAxios.get('/industries').then((response) => response.data.data);
  }

  public async requestIndustryValidation(industryCode: string, legalEntityName: string): Promise<void> {
    return myAxios.post(`/v1/industries/${industryCode}/validate`, { legalEntityName });
  }

  public async createMerchant(data: CreateMerchantRequest): Promise<{ hostedOnboardingLink?: string; errorMessage?: string }> {
    if (!data.site.address.line2) delete data.site.address.line2;
    if (!data.site.address.stateOrProvince) delete data.site.address.stateOrProvince;
    if (!data.site.payByLinkTermsUrl) delete data.site.payByLinkTermsUrl;
    if (!data.site.shortCode) delete data.site.shortCode;
    data.site.address.postalCode = data.site.address.postalCode.toUpperCase();
    return myAxios
      .post('/v1/customer-accounts', JSON.stringify(data), { headers: { 'Content-Type': 'application/json' } })
      .then((response) => ({ hostedOnboardingLink: response.data.data.hostedOnboardingLink }))
      .catch((error) => ({ errorMessage: error?.response?.data?.error ?? 'An error ocurred when processing this request' }));
  }

  public async requestOnboardingLink(merchantId: string): Promise<string> {
    return myAxios.post(`/merchants/${merchantId}/verification-links`, JSON.stringify('')).then((response) => response.data.data);
  }

  public async fetchMerchants(): Promise<MerchantWithPermissions[]> {
    return myAxios.get('/merchants').then((response) => response.data.data);
  }

  public async queryMerchantsByName(name: string): Promise<Merchant[]> {
    return myAxios.get(`/v1/merchants/staff-search?name=${name}`).then((response) => response.data.data);
  }

  public async getMerchant(merchantId: string): Promise<Merchant> {
    return myAxios.get(`/v1/merchants/${merchantId}`).then((response) => response.data);
  }

  public async transferMerchant(merchantId: string, email: string): Promise<void> {
    return myAxios.post(`/v1/merchants/${merchantId}/transfer`, { email });
  }

  public async updateMerchant(merchantId: string, attributes: Pick<Merchant, 'financeEmail'>): Promise<void> {
    return myAxios.put(`/v1/merchants/${merchantId}`, attributes);
  }

  public async associateDeal(merchantId: string, dealReference: string): Promise<void> {
    return myAxios.post(`/v1/merchants/${merchantId}/rates/associate`, {}, { params: { dealReference } });
  }

  public async getDeal(merchantId: string): Promise<DealRates> {
    return myAxios.get(`/v1/merchants/${merchantId}/rates`).then((response) => response.data);
  }

  public async deactivate(merchantId: string, stage: 'in-contract' | 'init' | 'complete', reason?: string): Promise<DealRates> {
    return myAxios.post(`/v1/merchants/${merchantId}/deactivate`, { reason }, { params: { stage } });
  }
}

export default MerchantApi;
