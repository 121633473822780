<template>
  <v-banner v-if="showBanner" sticky style="z-index: 9999" bg-color="error">
    <template #prepend><v-icon class="mt-4">mdi-alert-circle-outline</v-icon></template>
    <template #default>
      <div>
        <p>
          Your company account has been closed. You can only view historical data from this company. If you believe this to be in error, contact yetipay support
          using the chat option in the bottom right or email&nbsp;<a href="mailto:help@yetipay.me">help@yetipay.me</a>.
        </p>
        <br />
        <p>
          Use this link to return your devices:
          <a href="https://www.royalmail.com/track-my-return#/details/5864" target="_blank">https://www.royalmail.com/track-my-return#/details/5864</a>
        </p>
        <br />
        <b>You are liable for further charges should you not return the device(s) within 10 business days.</b>
      </div>
    </template>
  </v-banner>
  <div v-else></div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useMerchantsStore } from '@/store/merchants';

const merchantsStore = useMerchantsStore();
const showBanner = computed(() => merchantsStore.currentMerchant && merchantsStore.hasMerchantChurned(merchantsStore.currentMerchant));
</script>
