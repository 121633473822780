<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12" md="6">
        <v-card v-if="!showChangePasswordForm" class="pa-5 mt-12">
          <v-card-title>
            <h2 class="font-weight-medium">Reset password</h2>
          </v-card-title>
          <v-card-text>
            <p class="text-left">Enter your email and we will send you a link to reset your password.</p>
          </v-card-text>
          <v-card-text>
            <v-form @submit.prevent="submitForgotPasswordRequest">
              <v-text-field
                v-model="email"
                placeholder="Enter your email address"
                required
                density="compact"
                autocapitalize="none"
                autocomplete="email"
                :error-messages="(v$.email.$dirty && v$.email.$invalid && v$.email.$silentErrors[0].$message.toString()) || ''"
                @input="authStore.resetErrorMessages"
              ></v-text-field>
              <v-btn :disabled="isSubmitFormDisabled" class="mt-5" type="submit" block>Send code</v-btn>
            </v-form>
            <v-alert v-if="authStore.forgotPassword.errorMessage" density="compact" outlined type="warning" class="mt-4">
              {{ authStore.forgotPassword.errorMessage }}
            </v-alert>
          </v-card-text>
          <v-card-text>
            <p class="text-left">Back to <router-link to="/signin">Sign in</router-link></p>
          </v-card-text>
        </v-card>
        <v-card v-else class="pa-5 mt-12">
          <v-card-title>
            <h2 class="font-weight-medium">We've emailed you</h2>
          </v-card-title>
          <v-card-text>
            <p class="text-left">We've sent a password reset code to {{ email }}. Enter the reset code below, then set a new password.</p>
          </v-card-text>
          <v-card-text>
            <v-form autocomplete="off" @submit.prevent="submitChangePasswordRequest">
              <v-text-field
                v-model="resetCode"
                placeholder="Password reset code"
                class="no-inline-number-controls"
                required
                type="number"
                density="compact"
                autocapitalize="none"
                autocomplete="off"
                :error-messages="(v$.resetCode.$dirty && v$.resetCode.$invalid && v$.resetCode.$silentErrors[0].$message.toString()) || ''"
                @input="authStore.resetErrorMessages"
              ></v-text-field>
              <v-text-field
                v-model="newPassword"
                placeholder="New password"
                type="password"
                required
                density="compact"
                autocapitalize="none"
                autocomplete="off"
                :error-messages="(v$.newPassword.$dirty && v$.newPassword.$invalid && v$.newPassword.$silentErrors[0].$message.toString()) || ''"
                @input="authStore.resetErrorMessages"
              ></v-text-field>

              <v-btn :disabled="isChangePasswordFormDisabled" type="submit" block>Reset password</v-btn>
              <v-alert v-if="authStore.forgotPassword.errorMessage" density="compact" outlined type="warning" class="mt-4">
                {{ authStore.forgotPassword.errorMessage }}
              </v-alert>
            </v-form>
          </v-card-text>
          <v-card-text>
            <p class="text-left">Back to <router-link to="/signin">Sign in</router-link></p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { ref, reactive, computed } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, email as emailValidator, helpers } from '@vuelidate/validators';
import { useAuthenticationStore, LOADING_STATES } from '@/store/authentication';
import { useRouter } from 'vue-router';
import { useApplicationStore } from '@/store/application';
import { passwordValidations } from '@/utils/custom-validators';

const router = useRouter();
const authStore = useAuthenticationStore();
const applicationStore = useApplicationStore();

const showChangePasswordForm = ref(false);
const email = ref('');
const resetCode = ref('');
const newPassword = ref('');

const v$ = useVuelidate(
  {
    email: {
      $autoDirty: true,
      required: helpers.withMessage('Required', required),
      email: helpers.withMessage('Invalid email address', emailValidator),
    },
    resetCode: {
      $autoDirty: true,
      required: helpers.withMessage('Required', required),
    },
    newPassword: {
      $autoDirty: true,
      ...passwordValidations,
    },
  },
  reactive({ email, resetCode, newPassword }),
);

const isSubmitFormDisabled = computed(() => v$.value.email.$invalid || authStore.forgotPassword.status === LOADING_STATES.LOADING);
const isChangePasswordFormDisabled = computed(
  () => v$.value.resetCode.$invalid || v$.value.newPassword.$invalid || authStore.forgotPassword.status === LOADING_STATES.LOADING,
);

const submitForgotPasswordRequest = async () => {
  const emailLowercase = email.value.toLocaleLowerCase();
  const forgotRequestSuccess = await authStore.initForgotPassword(emailLowercase);
  if (forgotRequestSuccess) {
    showChangePasswordForm.value = true;
  }
};
const submitChangePasswordRequest = async () => {
  const emailLowercase = email.value.toLocaleLowerCase();
  const resetSuccess = await authStore.initConfirmNewPassword(emailLowercase, resetCode.value, newPassword.value);
  if (resetSuccess) {
    router.push('/signin');
    applicationStore.notifyUser({ message: 'Password reset successfully. Sign in with your new password to continue.' });
  }
};
</script>
