import Intercom, { update, onShow } from '@intercom/messenger-js-sdk';
import { useMerchantsStore } from '@/store/merchants';
import { useAuthenticationStore } from '@/store/authentication';

export default {
  install() {
    Intercom({
      app_id: import.meta.env.VITE_INTERCOM_APP_ID,
    });

    onShow(() => {
      const merchantsStore = useMerchantsStore();
      const authenticationStore = useAuthenticationStore();
      if (!authenticationStore.user.isAuthenticated) {
        return;
      }

      update({
        email: authenticationStore.user.email,
        user_id: authenticationStore.user.id,
        legalEntityNAme: merchantsStore.currentMerchant?.legalEntityName,
        internalId: merchantsStore.currentMerchant?.merchantId,
        stripeCustomerId: merchantsStore.currentMerchant?.stripeCustomerId,
      });
    });
  },
};
