<template>
  <v-layout full-height>
    <v-navigation-drawer v-model="drawer" elevation="5" color="primary" class="gradient-primary" :temporary="mobile">
      <v-list>
        <router-link to="/"><v-img src="@/assets/img/purple-logo-no-background.png" /></router-link>

        <v-divider />

        <div v-for="item in menu" :key="item.title">
          <v-list-group v-if="item.pages && (!item.hasAccess || item.hasAccess())" :value="item.title">
            <template #activator="{ props }">
              <v-list-item v-bind="props" :prepend-icon="item.icon" :title="item.title" />
            </template>
            <v-list-item
              v-for="page in item.pages.filter((page) => (!page.hasAccess || page.hasAccess()) && !page.hide)"
              :key="page.title"
              :to="page.to"
              :title="page.title"
              link
              @click="page.atClick"
            />
          </v-list-group>
          <v-list-item v-else-if="!item.hasAccess || item.hasAccess()" :to="item.to" :title="item.title" link :prepend-icon="item.icon" />
        </div>
      </v-list>
      <template #append>
        <v-dialog v-if="authorizationStore.isStaff" width="350">
          <template #activator="{ props }">
            <div class="pa-3 text-center" :class="merchantsStore.overridden && 'blink-warning'" style="cursor: pointer" v-bind="props">
              {{ merchantsStore.currentMerchant?.legalEntityName }}
            </div>
          </template>

          <template #default="{ isActive }">
            <v-card title="Override current customer">
              <v-form @submit.prevent>
                <v-container>
                  <v-combobox
                    v-model="overrideMerchant"
                    :items="merchants"
                    :loading="isLoadingMerchants"
                    label="Search Merchants"
                    item-title="legalEntityName"
                    item-value="merchantId"
                    hide-details
                    solo
                    clearable
                    return-object
                    @click:clear="() => fetchMerchants"
                    @update:search="fetchMerchants"
                  ></v-combobox>
                </v-container>

                <v-card-text>
                  Or enter the CustomerID of the customer you want to override:
                  <v-text-field
                    :model-value="overrideMerchant?.merchantId || ''"
                    autofocus
                    @update:model-value="(v) => (overrideMerchant = { merchantId: v, legalEntityName: '' })"
                  />
                </v-card-text>

                <v-card-actions>
                  <v-btn-nd text="Cancel" @click="isActive.value = false" />
                  <v-spacer></v-spacer>
                  <v-btn type="submit" text="Override" class="bg-primary on-primary" @click="() => changeMerchant() && (isActive.value = false)" />
                </v-card-actions>
              </v-form>
            </v-card>
          </template>
        </v-dialog>

        <v-select
          v-else-if="userHasMultipleCustomers"
          v-model="selectedMerchant"
          :items="merchantsStore.merchants"
          item-title="legalEntityName"
          item-value="merchantId"
          bg-color="background"
          density="compact"
          rounded="sm"
          class="mx-3"
        >
          <template #item="{ item, props }">
            <v-list-item v-bind="props" :append-icon="merchantDropdownIcon(item.raw as Merchant)" />
          </template>
        </v-select>

        <div v-else class="pa-3 text-center">{{ merchantsStore.currentMerchant?.legalEntityName }}</div>

        <NewCompanyConfirmation v-model="addCompanyModal" />
      </template>
    </v-navigation-drawer>

    <v-app-bar
      elevation="0"
      :color="(!merchantsStore.overridden && 'background') || undefined"
      :class="`${!mobile && 'px-11'} ${merchantsStore.overridden && 'blink-warning'}`"
    >
      <v-btn v-if="mobile" icon offset-y @click="drawer = !drawer">
        <v-icon color="primary">mdi-menu</v-icon>
      </v-btn>
      <v-breadcrumbs v-if="!mobile" :items="breadcrumbs">
        <template #prepend>
          <RouterLink to="/" class="d-flex no-underline"><v-icon left color="primary">mdi-home</v-icon></RouterLink>
        </template>
      </v-breadcrumbs>

      <template #append>
        <v-btn href="https://linktr.ee/yetipay.me" target="_blank">Support<v-icon class="ml-1" color="primary">mdi-face-agent</v-icon></v-btn>
        <v-menu offset-y>
          <template #activator="{ props }">
            <v-btn icon v-bind="props"><v-icon color="primary">mdi-account-circle</v-icon></v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title>
                <RouterLink :to="`/users/${authStore.user.id}`" class="text-decoration-none text-black">
                  <v-icon left>mdi-at</v-icon> {{ authStore.user.email }}
                </RouterLink>
              </v-list-item-title>
            </v-list-item>
            <v-list-item link @click.prevent="logout">
              <v-list-item-title><v-icon>mdi-logout</v-icon> Sign out</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-app-bar>

    <v-main style="height: 100vh; overflow: auto">
      <router-view v-slot="slot">
        <transition name="fade" mode="out-in">
          <div :key="route.path">
            <MainContent :name="slot.route.name as string" color="background">
              <component :is="slot.Component" />
            </MainContent>
          </div>
        </transition>
      </router-view>
    </v-main>

    <Footer />
  </v-layout>
</template>

<script setup lang="ts">
import { ref, computed, watch, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useDisplay } from 'vuetify';
import { PostHog } from 'posthog-js';
import { useApplicationStore } from '@/store/application';
import { useMerchantsStore } from '@/store/merchants';
import { useAuthenticationStore } from '@/store/authentication';
import { Permission, useAuthorizationStore } from '@/store/authorization';
import { Merchant } from '@/api/merchant-service/merchant';
import { API } from '@/plugins/api';

const posthog = inject<PostHog>('posthog');
const api = inject<API>('api');

const route = useRoute();
const router = useRouter();
const appStore = useApplicationStore();
const authStore = useAuthenticationStore();
const authorizationStore = useAuthorizationStore();
const merchantsStore = useMerchantsStore();
const { mobile } = useDisplay();

const breadcrumbs = computed(() => (route.meta.breadcrumbs ?? ['Home']).map((breadcrumb) => ({ title: breadcrumb })));
const merchantDropdownIcon = (merchant: Merchant) =>
  merchantsStore.hasMerchantBeenActivated(merchant) ? (merchantsStore.hasMerchantChurned(merchant) ? 'mdi-robot-dead' : undefined) : 'mdi-alert';

const addCompanyModal = ref(false);
const logout = async () => {
  await authStore.signOut();
  router.push('/signin');
};

const userHasMultipleCustomers = computed(() => (merchantsStore.merchants ?? []).length > 1);
const selectedMerchant = ref(merchantsStore.currentMerchant?.merchantId);

// Add user to PostHog group for their currentMerchant
if (merchantsStore.currentMerchant) {
  const formattedLegalEntityName = (merchantsStore.currentMerchant.legalEntityName ?? '').replace(/[^a-zA-Z0-9 ]/g, '');
  posthog?.group('company', formattedLegalEntityName, {
    merchantId: merchantsStore.currentMerchant.merchantId,
    selectedIndustries: merchantsStore.currentMerchant.selectedIndustries.join(''),
    country: merchantsStore.currentMerchant.country,
    type: merchantsStore.currentMerchant.type,
  });
}

watch(selectedMerchant, (value) => {
  try {
    merchantsStore.switch(value);
    window.location.href = '/'; // Hard reload the app, simpler than trying to update all the relevant stores
  } catch (error) {
    console.error(error);
    appStore.notifyUser({ message: 'Failed to switch customer. Contact support.', type: 'error' });
  }
});
const changeMerchant = () => {
  if (overrideMerchant.value) {
    merchantsStore.override(overrideMerchant.value.merchantId!);
  }
  return true;
};

const drawer = ref(!mobile.value);

interface Menu {
  title: string;
  icon: string;
  to?: string;
  hasAccess?: () => boolean;
  pages?: Array<{
    title: string;
    to?: string;
    atClick?: () => void;
    hasAccess?: () => boolean;
    hide?: boolean;
  }>;
}
const menu = ref<Menu[]>([
  {
    title: 'Devices',
    icon: 'mdi-cellphone',
    hasAccess: () => authorizationStore.hasAnySiteScoped(Permission.Device_ListDevices),
    pages: [
      {
        title: 'All devices',
        to: '/devices',
        hasAccess: () => authorizationStore.hasAnySiteScoped(Permission.Device_ListDevices),
      },
      {
        title: 'Order new device',
        to: '/request-new-device',
        hasAccess: () => authorizationStore.hasCompanyScoped(Permission.Device_OrderDevice),
      },
    ],
  },
  {
    title: 'Sites',
    icon: 'mdi-store',
    hasAccess: () => authorizationStore.hasAnySiteScoped(Permission.Site_ListSites),
    pages: [
      {
        title: 'All sites',
        to: '/sites',
        hasAccess: () => authorizationStore.hasAnySiteScoped(Permission.Site_ListSites),
      },
      {
        title: 'New site',
        to: '/new-site',
        hasAccess: () => authorizationStore.hasCompanyScoped(Permission.Site_CreateSite),
      },
    ],
  },
  {
    title: 'Reporting',
    icon: 'mdi-chart-bar',
    pages: [
      {
        title: 'Transactions',
        to: '/transactions',
        hasAccess: () => authorizationStore.hasAnySiteScoped(Permission.Transaction_ListTransactions),
      },
      {
        title: 'Payouts',
        to: '/payouts',
        hasAccess: () => authorizationStore.hasAnySiteScoped(Permission.Payout_ListPayouts),
      },
      {
        title: 'Reports',
        to: '/reports',
        hasAccess: () => authorizationStore.hasAnySiteScoped(Permission.Report_ListReports),
      },
    ],
  },
  {
    title: 'Billing',
    icon: 'mdi-cash',
    hasAccess: () => authorizationStore.canManageCompanies,
    pages: [
      {
        title: 'Payment methods',
        to: '/billing/payment-methods',
        hasAccess: () => authorizationStore.hasCompanyScoped(Permission.Billing_ListPaymentMethods),
      },
      {
        title: 'Invoices',
        to: '/billing/invoices',
        hasAccess: () => authorizationStore.hasCompanyScoped(Permission.Billing_ListInvoices),
      },
    ],
  },
  {
    title: 'Users',
    icon: 'mdi-account',
    to: '/users',
    hasAccess: () => authorizationStore.hasCompanyScoped(Permission.User_ListUsers),
  },
  {
    title: 'Company',
    icon: 'mdi-domain',
    hasAccess: () => authorizationStore.canManageCompanies,
    pages: [
      {
        title: 'Company settings',
        to: '/company-settings',
      },
      {
        title: 'Manage companies',
        to: '/companies',
        hide: merchantsStore.merchants.length < 2,
      },
      {
        title: 'Add company',
        atClick: () => (addCompanyModal.value = true),
      },
    ],
  },
]);

const merchants = ref<Partial<Merchant>[]>([]);
const overrideMerchant = ref<Partial<Merchant> | null>(null);
const isLoadingMerchants = ref(false);

const fetchMerchants = async (query?: string) => {
  if (!query) {
    merchants.value = [];
    return;
  }

  isLoadingMerchants.value = true;

  try {
    const fetched = await api!.merchant.queryMerchantsByName(query);

    merchants.value = fetched.map((item) => ({
      merchantId: item.merchantId,
      legalEntityName: item.legalEntityName,
    }));
  } catch (error) {
    console.error('Error fetching merchants', error);
  } finally {
    isLoadingMerchants.value = false;
  }
};
</script>

<style scoped>
@keyframes colorChange {
  0% {
    background-color: transparent;
  }
  5% {
    background-color: rgb(var(--v-theme-error));
  }
  75% {
    background-color: rgb(var(--v-theme-error));
  }
  100% {
    background-color: transparent;
  }
}

.blink-warning {
  animation: colorChange 1s linear infinite;
}
.no-underline {
  text-decoration: none;
}
</style>
