import { Amount } from '@/api/merchant-service/common-types';
import { myAxios } from '@/plugins/api';

export interface Payout {
  yetiTransferId: string;
  date: string;
  amount: Amount;
  payoutContent: DailySummary[];
  reason: string;
  siteId: string;
  siteName: string;
}

export interface DailySummary {
  date: string;
  totalProcessed: Amount;
  totalRefunded: Amount;
  totalChargeback: Amount;
  includedTip?: Amount;
  additionalTip?: Amount;
  totalMisc: Amount;
  subtotal: Amount;
}

export interface Transfer {
  transferId: string;
  pspPaymentReference: string;
  amount: Amount;
  includedTipAmount: number;
  additionalTipAmount: number;
  bookingDate: string;
  type: 'transaction' | 'refund' | 'chargeback' | 'internal transfer' | 'unknown';
  includedTip?: Amount;
  additionalTip?: Amount;
}

export interface PayoutLedger {
  date: string;
  amount: Amount;
  id: string;
  payoutSummary: {
    date: string;
    totalProcessed: number;
    totalRefunded: number;
    totalChargeback: number;
    totalMisc: number;
    subtotal: number;
    includedTip?: number;
    additionalTip?: number;
  }[];
  site: {
    siteName: string;
  };
  transfers: Transfer[];
  payoutTotalError: boolean;
}

class PayoutApi {
  private readonly merchantId: () => string;
  constructor(merchantId: () => string) {
    this.merchantId = merchantId;
  }

  public async listPayouts(siteId?: string, date?: string, lastKey?: string): Promise<{ items: Payout[]; lastKey: string }> {
    return myAxios.get(`/merchants/${this.merchantId()}/payouts`, { params: { siteId, date, lastKey } }).then((response) => response.data.data);
  }

  public async getPayout(payoutId: string): Promise<PayoutLedger> {
    return myAxios.get(`/v1/merchants/${this.merchantId()}/payouts/${payoutId}`).then((response) => response.data.data);
  }
}

export default PayoutApi;
