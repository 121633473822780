<template>
  <v-alert v-if="posthog?.isFeatureEnabled('show-review-of-data-alert')" icon="mdi-account-alert" :title="title">
    <v-container>
      <v-row>{{ mainCopy }}</v-row>
      <v-row class="mt-5">{{ actionCopy }}</v-row>
      <v-row v-if="hasPermissionToReviewData" justify="end" class="mt-10">
        <v-btn color="secondary" :loading="isLoading" @click="launchHostedOnboarding">Start now</v-btn>
      </v-row>
    </v-container>
  </v-alert>
</template>

<script setup lang="ts">
import { inject, ref } from 'vue';
import { PostHog } from 'posthog-js';
import { format } from 'date-fns';
import { API } from '@/plugins/api';
import { useMerchantsStore } from '@/store/merchants';
import { useApplicationStore } from '@/store/application';
import { useAuthorizationStore, Permission } from '@/store/authorization';

const api = inject<API>('api');
const posthog = inject<PostHog>('posthog');

const merchantsStore = useMerchantsStore();
const authorizationStore = useAuthorizationStore();

const hasPermissionToReviewData = authorizationStore.hasCompanyScoped(Permission.Company_AccessKyc);
const deadline = merchantsStore.currentMerchantReviewOfDateDeadline ? format(merchantsStore.currentMerchantReviewOfDateDeadline, 'do MMMM yyyy') : '';

const title = 'Action Required';
const mainCopy = `To keep records up to date, from time to time we need to ask you if there has been any change in your company information.`;
const actionCopy = hasPermissionToReviewData
  ? `You must review your company information by ${deadline} to ensure your company can continue to process payments.`
  : `Your company admin must review this information by ${deadline} to ensure you can continue to process payments.`;

const isLoading = ref();

const launchHostedOnboarding = async (): Promise<void> => {
  try {
    isLoading.value = true;
    const result: string = await api!.merchant.requestOnboardingLink(merchantsStore.currentMerchant!.merchantId);
    window.open(result, '_blank');
  } catch (error) {
    console.error(error);
    useApplicationStore().notifyUser({ message: 'Unable to load your company data, please contact support.', type: 'error' });
  } finally {
    isLoading.value = false;
  }
};
</script>
